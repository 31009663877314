import s from './Checkbox.module.scss';

type Props = {
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  checked?: boolean;
  onChange: (value: string) => void;
};

export default function Checkbox({ id, name, value, label, checked, onChange }: Props) {
  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div className={s.checkbox}>
      <input
        className={s.checkbox__input}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
      <span
        className={s.checkbox__checkmark}
        onClick={() => {
          handleChange(value ?? '');
        }}
      />
      <label className={s.checkbox__label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
