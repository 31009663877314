// Functions that handle clicks on the map

/*
 *+  Map click cases:
 ** (1) Click lands on a active staðfange (blue)
 ** --- Click lands on a staðfang (red)
 **    (2) There is a drawn skiki under the staðfang with the same landeignarnúmer (Same as (1))
 **    (3) There is a drawn skiki under the staðfang but with a different landeignarnúmer
 **    (4) There is a landeign under the staðfang with the same landeignarnumer
 **    (5) There is a landeign under the stadfang but it has a different landeignarnúmer
 **    (6) There is no landeign under the staðfang but the landeign still has a afmörkun
 **    (7) There is no landeign under the staðfang and the landeign doesn´t have a afmörkun
 ** --- Click lands on the active landeign
 **    (8) The clicked skiki is the selected one (cyan)
 **    (9) The clicked skiki is not the selected one (dark blue)
 ** (10) Click lands on the landeignir layer (yellow)
 ** (11) Click lands on nothing, just the base map
 */

import { Map, MapBrowserEvent, View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import VectorSource from 'ol/source/Vector';

import {
  featureToUiSkiki,
  featureToUmAfmorkun,
  landeignWmsToUiLandeign,
  landeignWmsToUmAfmorkun,
  resetClickUpdates,
} from '../../utils/convertion';
import {
  fetchSelectedLandeignByCoordinate,
  fetchSelectedLandeignByLandeignNr,
} from '../../utils/fetchSelectedLandeign';
import { getSveitarfelag } from '../../utils/getSveitarfelag';
import { setPath } from '../../utils/setPath';
import { makeFeatureInfoUrl } from '../../utils/urlUtils';
import {
  SOURCE_SELECTED_LANDEIGN,
  SOURCE_SELECTED_STADFONG,
  SOURCE_STADFONG,
} from '../layers/layers';

import { equalCoordinates, getPointCoordinates } from './ol-geometry-helpers';
import { skikarHoverAndSelectedStyle, skikarStyle } from './styles';

export const clearLandeign = () => {
  SOURCE_SELECTED_LANDEIGN.clear();
  SOURCE_SELECTED_STADFONG.clear();
};

const getLendeignFromClick = async (e: MapBrowserEvent<MouseEvent>, map: Map) => {
  SOURCE_SELECTED_LANDEIGN.clear();
  const url = makeFeatureInfoUrl(map, e.coordinate);

  if (!url) {
    return;
  }

  const selectedLandeign = await fetchSelectedLandeignByCoordinate(url);
  if (selectedLandeign && selectedLandeign.features.length > 0) {
    const landeignanumer = selectedLandeign.features[0].properties.LANDEIGN_NR;
    setPath(landeignanumer);
    clearLandeign();

    return selectedLandeign;
  }
};

type Values_ = {
  values_: {
    LANDNR: number;
    SVFNR: string;
  };
};

const handlePossibleLandeignClick = async (e: MapBrowserEvent<MouseEvent>, map: Map) => {
  const landeign = await getLendeignFromClick(e, map);

  if (!landeign) {
    return;
  }

  return {
    landeign: landeignWmsToUiLandeign(landeign),
    landeignarnumer: landeign.features[0].properties.LANDEIGN_NR,
    umAfmorkun: landeignWmsToUmAfmorkun(landeign),
  };
};

const drawnSkikiClicked = (coordinate: Coordinate) => {
  const features = SOURCE_SELECTED_LANDEIGN.getFeatures();
  let featureClicked: boolean | undefined;
  let skiki: string | undefined;

  features.forEach((feature) => {
    const geometry = feature.getGeometry();
    const intersects = geometry?.intersectsCoordinate(coordinate);

    if (intersects) {
      const skikiFromFeature = feature.get('skiki') as string | undefined;
      feature.setStyle(skikarHoverAndSelectedStyle);
      feature.setProperties({ selected: true });
      skiki = skikiFromFeature;
      featureClicked = true;
    } else {
      feature.setStyle(skikarStyle);
      feature.setProperties({ selected: false });
    }
  });

  return { skiki, featureClicked };
};

const coordinateHasPointInSource = (
  clickCoordinate: Coordinate,
  source: VectorSource
) => {
  const features = source.getFeatures();
  let clickedPoint: FeatureLike | undefined;
  features.forEach((feature) => {
    const coordinate = getPointCoordinates(feature);
    if (!coordinate) {
      return;
    }

    const equal = equalCoordinates(coordinate, clickCoordinate);
    if (equal) {
      clickedPoint = feature;
    }
  });
  return clickedPoint;
};

const shiftViewFromUnderInfoWindow = (
  view: View,
  width: number,
  height: number,
  x: number,
  y: number,
  clickCoordinate: Coordinate,
  mobileCenter: Coordinate
) => {
  if (width > 1400) {
    // Info window is maximum 560px when desktop or larger
    if (x > width - 560) {
      view.animate({ center: clickCoordinate });
    }
  } else if (width > 720) {
    // Info window is maximum 480px for tablet
    if (x > width - 480) {
      view.animate({ center: clickCoordinate });
    }
  } else {
    // Info covers 40vh of screen in mobile
    if (height - y > height * 0.6) {
      view.animate({ center: mobileCenter });
    }
  }
};

const handleStadfangClick = async (
  e: MapBrowserEvent<MouseEvent>,
  map: Map,
  feature: FeatureLike
) => {
  const values = (feature as unknown as Values_).values_;
  const landeignarnumer = values.LANDNR;
  const sveitarfelag = values.SVFNR;

  if (landeignarnumer) {
    const landeign = await fetchSelectedLandeignByLandeignNr(landeignarnumer);

    if (!landeign) {
      return;
    }

    setPath(`${landeignarnumer}`);

    const features = landeign.features;
    const properties = features[0] ? features[0].properties : undefined;
    const umAfmorkun = features[0] ? featureToUmAfmorkun(features[0]) : undefined;

    return {
      landeign: {
        audkenni: `L${landeignarnumer}`,
        sveitarfelag: getSveitarfelag(sveitarfelag),
        skradStaerd: properties?.LANDEIGN_SKRAD_STAERD ?? 0,
        maeldStaerd: properties?.LANDEIGN_MAELD_STAERD ?? 0,
      },
      skikar: {
        count: features.length,
        skikar: features.map((feature) => {
          return featureToUiSkiki(feature);
        }),
      },
      umAfmorkun,
      selectedLandeignarnumer: landeignarnumer,
    };
  }
};

// eslint-disable-next-line complexity
export const handleMapClick = async (
  map: Map,
  e: MapBrowserEvent<MouseEvent>,
  width: number,
  height: number
) => {
  const clickCoordinate = map.getCoordinateFromPixel(e.pixel);
  const { skiki, featureClicked } = drawnSkikiClicked(clickCoordinate);
  const plainStadfangClicked = coordinateHasPointInSource(
    clickCoordinate,
    SOURCE_STADFONG
  );
  const activeStadfangClicked = coordinateHasPointInSource(
    clickCoordinate,
    SOURCE_SELECTED_STADFONG
  );
  if (activeStadfangClicked) {
    // (1) og (2) Selected staðfang clicked (blue)
    return {};
  } else if (plainStadfangClicked) {
    // (3), (4), (5), (6) or (7) Staðfang click (red)
    const features = map.getFeaturesAtPixel(e.pixel);
    if (features.length !== 0) {
      clearLandeign();
      const info = await handleStadfangClick(e, map, plainStadfangClicked);
      return { info };
    }
  } else if (featureClicked) {
    // (8) or (9) Click on a drawn skiki
    // Handled by drawnSkikiClicked
    return { skiki, info: undefined };
  }

  const landeignClicked = await handlePossibleLandeignClick(e, map);
  if (landeignClicked) {
    // (10) Click on the landeign layer (yellow)
    const mobileCenter = map.getCoordinateFromPixel([
      e.pixel[0],
      Math.round(e.pixel[1] - height * 0.15),
    ]);
    shiftViewFromUnderInfoWindow(
      map.getView(),
      width,
      height,
      e.pixel[0],
      e.pixel[1],
      clickCoordinate,
      mobileCenter
    );

    const landNr = landeignClicked.landeignarnumer;
    setPath(landNr);

    return {
      info: {
        landeign: landeignClicked.landeign,
        umAfmorkun: landeignClicked.umAfmorkun,
        selectedLandeignarnumer: landNr,
      },
    };
  } else {
    // (11) Clicked nothing
    SOURCE_SELECTED_STADFONG.clear();
    setPath();
    return { info: resetClickUpdates() };
  }
};
