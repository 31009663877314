import { useContext, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import { UIContext } from '../../context/ui';
import { SOURCE_DRAW } from '../../open-layers/layers/layers';
import { toggleDrawInteraction } from '../../open-layers/utils/ol-measure-utils';
import Icon from '../icon/Icon';

import s from './Measures.module.scss';

const c = classnames.bind(s);

export default function Measures() {
  const { uiState, setUiState } = useContext(UIContext);
  const { mLength, mArea, mAngle, theMap } = uiState;
  const [tool, setTool] = useState<null | 'length' | 'area' | 'angle'>(null);

  const clearMeasureLayer = () => {
    SOURCE_DRAW.clear();
    const markers = document.querySelectorAll('.ol-overlay-container');
    const numberOfMarkers = markers.length;

    markers.forEach((marker, i) => {
      if (i !== numberOfMarkers - 1) {
        marker.parentNode?.removeChild(marker);
      }
    });
  };

  useEffect(() => {
    if (!theMap) {
      return;
    }
    const on = (mLength || mArea || mAngle) ?? false;
    const type = mLength ? 'LineString' : mArea ? 'Polygon' : mAngle ? 'Angle' : 'Off';
    toggleDrawInteraction(on, theMap, type);

    if (mLength) {
      setTool('length');
    } else if (mArea) {
      setTool('area');
    } else if (mAngle) {
      setTool('angle');
    } else {
      setTool(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mLength, mArea, mAngle]);

  const measure = (val?: 'length' | 'area' | 'angle') => {
    if (val === 'length') {
      setUiState({ mLength: true, mArea: false, mAngle: false, mapClickOn: false });
      setTool(val);
    } else if (val === 'area') {
      setUiState({ mLength: false, mArea: true, mAngle: false, mapClickOn: false });
      setTool(val);
    } else if (val === 'angle') {
      setUiState({ mLength: false, mArea: false, mAngle: true, mapClickOn: false });
    } else {
      setUiState({ mLength: false, mArea: false, mAngle: false, mapClickOn: true });
      setTool(null);
    }
  };

  return (
    <div className={s.measures}>
      <button
        className={c(s.measures__iconWrapper, { activeTool: tool === 'length' })}
        onClick={() => measure('length')}
      >
        <Icon icon="line" />
      </button>
      <button
        className={c(s.measures__iconWrapper, { activeTool: tool === 'area' })}
        onClick={() => measure('area')}
      >
        <Icon icon="area" />
      </button>
      <button
        className={c(s.measures__iconWrapper, { activeTool: tool === 'angle' })}
        onClick={() => measure('angle')}
      >
        <Icon icon="angle" />
      </button>
      <button className={s.measures__iconWrapper} onClick={clearMeasureLayer}>
        <Icon icon="trash" />
      </button>
    </div>
  );
}
