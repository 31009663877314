import { Feature, Map } from 'ol';
import { EventsKey } from 'ol/events';
import { Polygon } from 'ol/geom';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';

import { formatArea } from '../../utils/formats';
import { SOURCE_DRAW } from '../layers/layers';
import { segmentStyle } from '../utils/styles';

let sketch: Feature | undefined;
let measureTooltipElement: HTMLDivElement | undefined;
let measureTooltip: Overlay | undefined;

const addPolygon = new Draw({
  source: SOURCE_DRAW,
  type: 'Polygon',
  style: segmentStyle,
  condition: function (e: MapBrowserEvent<MouseEvent>) {
    // when the point's button is 1(leftclick), allows drawing
    return e.originalEvent.buttons === 1 ? true : false;
  },
});

const snap = new Snap({ source: SOURCE_DRAW });

const createMeasureTooltip = (map: Map) => {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode?.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center',
    stopEvent: false,
    insertFirst: false,
  });
  map.addOverlay(measureTooltip);
};

export function measureArea(map: Map) {
  map.addInteraction(addPolygon);
  map.addInteraction(snap);

  createMeasureTooltip(map);

  let listener: EventsKey | undefined;
  addPolygon.on('drawstart', function (e: DrawEvent) {
    sketch = e.feature;

    listener = sketch.getGeometry()?.on('change', (e) => {
      const geom: Polygon = e.target;
      const output = formatArea(geom);
      const tooltipCoord = geom.getInteriorPoint().getCoordinates();

      if (measureTooltipElement && measureTooltip) {
        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      }
    });
  });

  addPolygon.on('drawend', () => {
    if (measureTooltipElement) {
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      measureTooltip?.setOffset([0, -7]);
    }

    sketch = undefined;
    measureTooltipElement = undefined;
    createMeasureTooltip(map);

    if (listener) {
      unByKey(listener);
    }
  });
}

export function stopMeasuringArea(map: Map) {
  map.removeInteraction(addPolygon);
  map.removeInteraction(snap);
}
