import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { UIProvider } from './context/ui';
import Home from './pages/home/Home';

import './styles/global.scss';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UIProvider>
        <Home />
      </UIProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
