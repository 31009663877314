import { useContext } from 'react';

import { UIContext } from '../../context/ui';
import AttentionBox from '../atention-box/AttentionBox';
import Icon from '../icon/Icon';

import s from './Info.module.scss';

export default function Info() {
  const { setUiState } = useContext(UIContext);

  const onClose = () => {
    setUiState({
      isNavOpen: false,
      toolsOpen: false,
    });
  };

  return (
    <div className={s.info}>
      <div className={s.info__topbar}>
        <h1 className={s.info__title}>Upplýsingar um vefsjá Landeignaskrár</h1>
        <button className={s.info__close} onClick={onClose}>
          <Icon icon="x" />
        </button>
      </div>
      <div className={s.info__paragraphWrapper}>
        <p className={s.info__paragraph}>
          Frekari upplýsingar um fasteignaskrá og landeignaskrá eru á{' '}
          <a
            className={s.info__link}
            href="https://fasteignaskra.is/"
            rel="noreferrer"
            target="_blank"
          >
            fasteignaskra.is
          </a>
        </p>
      </div>
      <div className={s.info__paragraphWrapper}>
        <h2 className={s.info__heading}>Fyrirvari</h2>
        <p className={s.info__paragraph}>
          Gögn í landeignaskrá byggja ýmist á innsendum, hnitsettum mæliblöðum frá
          sveitarfélögum eða eldri þinglýstum uppdráttum sem hefur verið varpað. Um
          uppruna og nákvæmni hverrar afmörkunar segir í eigindum hennar undir "Um
          skráningu".
        </p>
        <AttentionBox
          richText={
            <p>
              Athugið að loftmyndir og kort sem boðið er uppá sem undirlag geta innihaldið
              allt að 10m skekkju.
            </p>
          }
        />
      </div>
      <div className={s.info__paragraphWrapper}>
        <h2 className={s.info__heading}>Eigendur</h2>
        <p className={s.info__paragraph}>
          Upplýsingar um eignarhald lands og eignarhlutfall eru skráð í þinglýsingarkerfi
          sýslumanna. Athugasemdir og beiðni um leiðréttingar eða breytingar skal beint
          til sýslumanns í því umdæmi þar sem viðkomandi eign er staðsett.
        </p>
      </div>
      <div className={s.info__paragraphWrapper}>
        <h2 className={s.info__heading}> Landeignaskrá </h2>
        <p className={s.info__paragraph}>
          Þessi vefsjá sýnir afmörkun þeirra landeigna sem færðar hafa verið inn í
          landeignaskrá fasteignaskrár. Sem stendur er hér aðeins að finna lítinn hluta
          þeirra landeigna sem skráðar eru í fasteignaskrá, en hlutfallið fer ört
          stækkandi. Ef landeignin sem þú vilt fá upplýsingar um á ekki afmörkun í þessari
          vefsjá er ástæðan líklegast önnur tveggja eftirfarandi:
        </p>
        <ul className={s.info__unorderedList}>
          <li className={s.info__listItem}>
            Landeignin hefur enga skilgreinda afmörkun eða heimildir um afmörkun hennar
            eru aðeins varðveittar í rituðu máli, s.s. Landamerkjalýsingum.
          </li>
          <li className={s.info__listItem}>
            Ekki hefur unnist tími til að færa afmörkun landeignar inn í
            landupplýsingagrunn HMS.
          </li>
        </ul>
        <p className={s.info__paragraph}>
          Nánari upplýsingar um skráningu landeigna, landamörk og afmörkun eigna má
          nálgast hér:
        </p>
        <p className={s.info__paragraph}>
          <a
            className={s.info__link}
            href="https://www.skra.is/fasteignir/skraning-fasteigna/"
            rel="noreferrer"
            target="_blank"
          >
            Skráning fasteigna
          </a>
        </p>
      </div>
      <div className={s.info__paragraphWrapper}>
        <h2 className={s.info__heading}> Staðfangaskrá</h2>
        <p className={s.info__paragraph}>
          Hugtakið staðfang er nýyrði í íslensku (e. access address). Staðfang hefur að
          geyma bæði lýsandi og rúmfræðilegar upplýsingar um staðsetningu. Með lýsandi
          upplýsingum er til að mynda átt við í hvaða sveitarfélagi, bæ eða hverfi
          staðfangið er að finna og við hvaða götu það er staðsett. Með rúmfræðilegum
          upplýsingum er átt við tvívítt hnit í samræmdu landshnitakerfi. Staðfangaskrá
          inniheldur hnit fyrir 96% allra skráðra fasteigna. Flest staðföng svara til
          mannvirkja eða mannvirkjahluta. Þar sem ekkert mannvirki er á landeign, fær
          landeignin sjálf staðfang.
        </p>
        <p className={s.info__paragraph}>
          <a
            className={s.info__link}
            href="https://www.skra.is/fasteignir/skraning-fasteigna/"
            rel="noreferrer"
            target="_blank"
          >
            Skráning fasteigna
          </a>
        </p>
      </div>
      <div className={s.info__paragraphWrapper}>
        <h2 className={s.info__heading}>Nytjaland 2006</h2>
        <p className={s.info__paragraph}>
          Í þessum grunni er að finna gögn frá árinu 2006 um landsvæði jarða sem safnað
          var í átaksverkefninu Nytjaland. Markmið verkefnisins var að meta stærð og gæði
          bújarða m.t.t. ræktanlegs lands og eru heimildir um legu landamerkja af ólíkum
          toga og misáreiðanlegar. Línurnar voru einnig dregnar upp með misnákvæmum
          aðferðum.
        </p>
        <AttentionBox
          richText={
            <p>
              Athugið að línurnar hafa enga lagalega þýðingu og ber aðeins að taka þeim
              sem vísbendingu fyrir frekari heimildaöflun. Þinglýst landamerkjabréf jarða
              má nálgast hjá viðkomandi sýslumannsembætti.
            </p>
          }
        />
      </div>
      <div className={s.info__paragraphWrapper}>
        <h2 className={s.info__heading}>Fasteignaskrá hjá HMS</h2>
        <p className={s.info__paragraph}>Hefurðu spurningar eða athugasemdir?</p>
        <p className={s.info__paragraph}>
          Endilega hafðu samband við okkur með því að senda póst á{' '}
          <a className={s.info__link}>land@hms.is</a>
        </p>
      </div>
    </div>
  );
}
