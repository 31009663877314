import { useContext, useEffect } from 'react';

import Tools from '../components/tools/Tools';
import { UIContext } from '../context/ui';
import { stopMeasuringArea } from '../open-layers/tools/measure-area';
import { stopMeasuringLength } from '../open-layers/tools/measure-length';
import { stopMeasuringAngle } from '../open-layers/tools/meaure-angle';
import { clearLandeign } from '../open-layers/utils/ol-click-utils';
import { setPath } from '../utils/setPath';

export type WindowType = 'search' | 'info' | 'changeMaps' | 'measure' | 'layers' | false;

export default function ToolsContainer() {
  const { uiState, setUiState } = useContext(UIContext);
  const { landeign, skikar, eigendur, stadfong, umAfmorkun, theMap } = uiState;

  useEffect(() => {
    if (
      landeign !== undefined ||
      skikar !== undefined ||
      eigendur !== undefined ||
      stadfong !== undefined ||
      umAfmorkun !== undefined
    ) {
      setUiState({ toolsOpen: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landeign, skikar, eigendur, stadfong, umAfmorkun]);

  const changeWindow = (change: WindowType) => {
    if (change === uiState.toolsOpen) {
      setUiState({
        toolsOpen: false,
        mLength: false,
        mArea: false,
        mAngle: false,
        mapClickOn: true,
      });
      if (theMap) {
        stopMeasuringLength(theMap);
        stopMeasuringArea(theMap);
        stopMeasuringAngle(theMap);
      }
      return;
    } else if (change !== 'measure') {
      setUiState({
        mLength: false,
        mArea: false,
        mAngle: false,
        landeign: undefined,
        skikar: undefined,
        umAfmorkun: undefined,
        stadfong: undefined,
        eigendur: undefined,
        selectedLandeignarnumer: undefined,
        toolsOpen: change,
        mapClickOn: true,
      });
      if (theMap) {
        stopMeasuringLength(theMap);
        stopMeasuringArea(theMap);
        stopMeasuringAngle(theMap);
      }
    } else {
      setUiState({
        landeign: undefined,
        skikar: undefined,
        umAfmorkun: undefined,
        stadfong: undefined,
        eigendur: undefined,
        selectedLandeignarnumer: undefined,
        toolsOpen: change,
      });
    }

    clearLandeign();
    setPath();
  };

  return <Tools changeWindow={changeWindow} />;
}
