import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import OSM from 'ol/source/OSM';
import TileImage from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';

import { resolutions } from '../utils/constants';

//Grunnkort byggt á landakorti af Íslandi frá Map.is
export const Kortmynd = new TileLayer({
  source: new TileImage({
    crossOrigin: null,
    tileGrid: new TileGrid({
      extent: [0, 0, 1048576, 1048576],
      resolutions: resolutions,
    }),
    url: 'https://ms.map.is/mapcache/tms/1.0.0/kortmynd_tjodsk@isn93/{z}/{x}/{-y}.jpg',
    attributions: 'Kortmynd - Loftmyndir ehf.',
  }),
  visible: true,
});

//OpenStreetMap grunnkort
export const OpenStreetMap = new TileLayer({
  source: new OSM(),
  visible: false,
});

//Bing Maps grunnkort - Einfalt loftmyndakort
export const BingMap = new TileLayer({
  source: new BingMaps({
    key: 'AsQF4yVBxo1XzLqGCRSZZsYnJy5dd1rqHut2vn9isHuqZDfNE1Ei6_pdh_IqAiWg',
    imagerySet: 'Aerial',
    maxZoom: 19,
  }),
  visible: false,
});
