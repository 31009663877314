import ReactTooltip from 'react-tooltip';

import useWindowDimensions from '../../utils/useWindowDimentions';
import Icon from '../icon/Icon';

import s from './InfoHeading.module.scss';

type Props = {
  id: string;
  heading: string;
  info: boolean;
  infoText1?: string;
  infoText2?: string;
  countBubble?: React.ReactNode;
};

export default function InfoHeading({
  id,
  heading,
  info = false,
  infoText1,
  infoText2,
  countBubble,
}: Props) {
  const { viewportWidth } = useWindowDimensions();

  return (
    <>
      <a className={s.infoHeading} data-tip data-for={id}>
        <div className={s.infoHeading__inner}>
          <h2 className={s.infoHeading__heading}>{heading}</h2>
          {countBubble}
          {info && (
            <div className={s.infoHeading__iconWrapper}>
              <Icon icon="info" />
            </div>
          )}
        </div>
      </a>

      {info && (
        <ReactTooltip
          id={id}
          className={s.infoHeading__tooltip}
          place={viewportWidth > 700 ? 'left' : 'bottom'}
          effect={'solid'}
          type={'dark'}
        >
          <div className={s.infoHeading__tooltipWrapper}>
            <p>{infoText1}</p>
            {infoText2 && <p>{infoText2}</p>}
          </div>
        </ReactTooltip>
      )}
    </>
  );
}
