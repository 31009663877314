/* eslint-disable complexity */
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames/bind';

import { WindowType } from '../../containers/ToolsContainer';
import { UIContext } from '../../context/ui';
import { setPath } from '../../utils/setPath';
import ChangeMaps from '../change-maps/ChangeMaps';
import Icon from '../icon/Icon';
import Info from '../info/Info';
import Layers from '../layers/Layers';
import Measures from '../measures/Measures';
import Search from '../search/Search';

import s from './Tools.module.scss';

const c = classnames.bind(s);

type Props = {
  changeWindow: (change: WindowType) => void;
};

export default function Tools({ changeWindow }: Props) {
  const { uiState } = useContext(UIContext);
  const { toolsOpen } = uiState;
  const place = 'right';
  const type = 'light';
  const effect = 'solid';

  return (
    <div className={c(s.tools, { active: toolsOpen })}>
      <div className={s.tools__iconWrapper}>
        <Link to="/" className={s.tools__logo} tabIndex={0}>
          <div>
            <Icon icon="hms" wide onClick={() => setPath()} />
          </div>
        </Link>
        <div
          className={c(s.tools__icon, { toolActive: toolsOpen === 'search' })}
          onClick={() => changeWindow('search')}
        >
          <a data-tip data-for="searchIcon" className={s.tools__anchor}>
            <button className={s.tools__button} tabIndex={0}>
              <Icon icon="search" />
            </button>
          </a>
        </div>
        {toolsOpen !== 'search' && (
          <ReactTooltip
            id="searchIcon"
            className={s.tools__tooltip}
            place={place}
            effect={effect}
            type={type}
          >
            <p>Leita að landeign</p>
          </ReactTooltip>
        )}
        <div
          className={c(s.tools__icon, { toolActive: toolsOpen === 'layers' })}
          onClick={() => changeWindow('layers')}
        >
          <a data-tip data-for="layersIcon" className={s.tools__anchor}>
            <button className={s.tools__button} tabIndex={0}>
              <Icon icon="layers" />
            </button>
          </a>
        </div>
        {toolsOpen !== 'layers' && (
          <ReactTooltip
            id="layersIcon"
            place={place}
            effect={effect}
            type={type}
            className={s.tools__tooltip}
          >
            <p>Velja kortaþekjur</p>
          </ReactTooltip>
        )}
        <div
          className={c(s.tools__icon, { toolActive: toolsOpen === 'changeMaps' })}
          onClick={() => changeWindow('changeMaps')}
        >
          <a data-tip data-for="mapsIcon">
            <button className={s.tools__button} tabIndex={0}>
              <Icon icon="iceland" />
            </button>
          </a>
        </div>
        {toolsOpen !== 'changeMaps' && (
          <ReactTooltip
            id="mapsIcon"
            place={place}
            effect={effect}
            type={type}
            className={s.tools__tooltip}
          >
            <p>Skipta um grunnkort</p>
          </ReactTooltip>
        )}
        <div
          className={c(s.tools__icon, { toolActive: toolsOpen === 'measure' })}
          onClick={() => changeWindow('measure')}
        >
          <a data-tip data-for="measureIcon">
            <button className={s.tools__button} tabIndex={0}>
              <Icon icon="measure" />
            </button>
          </a>
        </div>
        {toolsOpen !== 'measure' && (
          <ReactTooltip
            id="measureIcon"
            place={place}
            effect={effect}
            type={type}
            className={s.tools__tooltip}
          >
            <p>Mæla vegalengd eða flatarmál</p>
          </ReactTooltip>
        )}
        <div
          className={c(s.tools__infoIcon, { toolActive: toolsOpen === 'info' })}
          onClick={() => changeWindow('info')}
        >
          <a data-tip data-for="infoIcon">
            <button className={s.tools__button} tabIndex={0}>
              <Icon icon="info" />
            </button>
          </a>
        </div>
        {toolsOpen !== 'info' && (
          <ReactTooltip
            id="infoIcon"
            place={place}
            effect={effect}
            type={type}
            className={s.tools__tooltip}
          >
            <p>Upplýsingar um vefsjána</p>
          </ReactTooltip>
        )}
      </div>
      {uiState.toolsOpen === 'info' && (
        <div className={s.tools__infoWrapper}>
          <Info />
        </div>
      )}
      <div className={s.tools__window}>
        <div className={s.tools__windowDiv}>
          {uiState.toolsOpen === 'search' && <Search changeWindow={changeWindow} />}
        </div>
        <div className={s.tools__windowDiv}>
          {uiState.toolsOpen === 'layers' && <Layers />}
        </div>
        <div className={s.tools__windowDiv}>
          {uiState.toolsOpen === 'changeMaps' && <ChangeMaps />}
        </div>
        {uiState.toolsOpen !== 'changeMaps' && (
          <div className={s.tools__windowDiv}>
            {uiState.toolsOpen === 'measure' && <Measures />}
          </div>
        )}
      </div>
    </div>
  );
}
