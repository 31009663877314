import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import BaseEvent from 'ol/events/Event';
import LineString from 'ol/geom/LineString';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';

import { formatLength } from '../../utils/formats';
import { SOURCE_DRAW } from '../layers/layers';
import { segmentStyle } from '../utils/styles';

const drawLength = new Draw({
  source: SOURCE_DRAW,
  type: 'LineString',
  style: segmentStyle,
  condition: function (e: MapBrowserEvent<MouseEvent>) {
    // when the point's button is 1(leftclick), allows drawing
    return e.originalEvent.buttons === 1 ? true : false;
  },
});

const snap = new Snap({ source: SOURCE_DRAW });

let measureTooltipElement: HTMLDivElement | undefined;
let measureTooltip: Overlay;

const createMeasureTooltip = (map: Map) => {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode?.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center',
    stopEvent: false,
    insertFirst: false,
  });
  map.addOverlay(measureTooltip);
};

export function measureLength(map: Map) {
  map.addInteraction(drawLength);
  map.addInteraction(snap);
  createMeasureTooltip(map);

  let sketch;
  let listener: EventsKey | undefined;

  drawLength.on('drawstart', (e: DrawEvent) => {
    sketch = e.feature;
    let tooltipCoord: Coordinate;

    listener = sketch.getGeometry()?.on('change', (evt: BaseEvent) => {
      const geom = evt.target;
      let output;
      if (geom instanceof LineString) {
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }

      if (measureTooltipElement && output) {
        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      }
    });
  });

  drawLength.on('drawend', function () {
    if (measureTooltipElement) {
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
    }

    measureTooltip.setOffset([0, -7]);
    // unset sketch
    sketch = null;
    // unset tooltip so that a new one can be created
    measureTooltipElement = undefined;
    createMeasureTooltip(map);

    if (listener) {
      unByKey(listener);
    }
  });
}

export function stopMeasuringLength(map: Map) {
  map.removeInteraction(drawLength);
  map.removeInteraction(snap);
}
