import { get as getProjection } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

import { isn93Url } from './constants';

export const getISN93Projection = async () => {
  // TODO, API could also cache this since it's very static
  let res;
  try {
    const resp = await fetch(isn93Url);
    res = await resp.json();
  } catch (e) {
    console.warn('unable to get ISN93 Projection at: ', isn93Url, e);
  }

  if (res && 'results' in res && 'proj4' in res.results[0]) {
    proj4.defs('EPSG:3057', res.results[0].proj4);
    register(proj4);
    const ISN93 = getProjection('EPSG:3057');
    if (ISN93 != null) {
      return ISN93;
    }
  }
};
