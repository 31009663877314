import {
  UiEigendur,
  UiLandeign,
  UiSkikar,
  UiSkiki,
  UiStadfong,
  UmAfmorkun,
} from '../context/ui';
import {
  Feature,
  Gjaldandi,
  LandeignWMS,
  SkikarWMS,
  StadfangFeature,
} from '../types/apiTypes';

import { getSveitarfelag } from './getSveitarfelag';

export const landeignWmsToUiLandeign = (l: LandeignWMS) => {
  if (!l.features[0]) {
    return;
  }

  const prop = l.features[0].properties;
  const converted: UiLandeign = {
    audkenni: `L${prop.LANDEIGN_NR}`,
    sveitarfelag: getSveitarfelag(prop.SVEITARFELAG_NR),
    gerd: prop.LANDEIGN_GERD,
    skradStaerd: prop.LANDEIGN_SKRAD_STAERD,
    maeldStaerd: prop.LANDEIGN_MAELD_STAERD,
  };

  return converted;
};

export const landeignWmsToUmAfmorkun = (l: LandeignWMS) => {
  if (!l.features[0]) {
    return;
  }

  const prop = l.features[0].properties;

  const converted: UmAfmorkun = {
    adferd: prop.ADFERD_INN ?? 'Ekki vitað',
    nakvaemni: prop.NAKVAEMNI ? prop.NAKVAEMNI.toString() : 'Ekki vitað',
    heimild: prop.HEIMILD,
    athugasemd: prop.ATHUGASEMD,
    audkenni: prop.SKIKI_AUDK,
  };

  return converted;
};

export const skikarWmsToSkikar = (s: SkikarWMS | null) => {
  const count = s?.totalFeatures ?? 0;
  const skikar = s?.features
    .map((skiki) => {
      const geometry = skiki.geometry;
      return {
        skiki: skiki.properties.SKIKI_AUDK,
        maeldStaerd: skiki.properties.SKIKI_MAELD_STAERD,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        coordinates: geometry ? geometry.coordinates : undefined,
        // For umAfmorkun
        adferd: skiki.properties.ADFERD_INN,
        nakvaemni: skiki.properties.NAKVAEMNI,
        heimild: skiki.properties.HEIMILD,
        athugasemd: skiki.properties.ATHUGASEMD,
      };
    })
    .sort((a, b) => {
      if (!a.skiki || !b.skiki) {
        return 0;
      }
      const nrA = parseInt(a.skiki.split('/')[0]);
      const nrB = parseInt(b.skiki.split('/')[0]);

      if (nrA > nrB) {
        return 1;
      } else if (nrA < nrB) {
        return -1;
      } else {
        return 0;
      }
    });

  const uiSkikar: UiSkikar = {
    count,
    skikar,
  };
  return uiSkikar;
};

export const stadfangFeaturesToUiStadfong = (s: Array<StadfangFeature>) => {
  const count = s.length;
  const stadfong = s.map((stadfang) => {
    return stadfang.properties;
  });

  const uiStadfong: UiStadfong = {
    count,
    stadfong,
  };

  return uiStadfong;
};

export const gjaldendurToUiEigendur = (g: Array<Gjaldandi>) => {
  const uiEigendur: Array<UiEigendur> = g.map((gjaldandi) => {
    return {
      name: gjaldandi.nafn,
      ratioOwned: gjaldandi.eighl,
    };
  });

  return uiEigendur;
};

export const uiSkikiToUmAfmorkun = (s: UiSkiki) => {
  return {
    umAfmorkun: {
      adferd: s.adferd,
      nakvaemni: `${s.nakvaemni ?? 'Ekki vitað'}`,
      heimild: s.heimild,
      athugasemd: s.athugasemd ?? undefined,
      audkenni: s.skiki,
    },
  };
};

export const featureToUiSkiki = (f: Feature) => {
  const p = f.properties;
  const g = f.geometry;
  return {
    skiki: p.SKIKI_AUDK,
    maeldStaerd: p.SKIKI_MAELD_STAERD,
    coordinates: g ? g.coordinates : undefined,
    adferd: p.ADFERD_INN,
    nakvaemni: p.NAKVAEMNI,
    heimild: p.HEIMILD,
    athugasemd: p.ATHUGASEMD,
  };
};

export const featureToUmAfmorkun = (f: Feature) => {
  const p = f.properties;
  return {
    adferd: p.ADFERD_INN,
    nakvaemni: `${p.NAKVAEMNI ?? ''}`,
    heimild: p.HEIMILD,
    athugasemd: p.ATHUGASEMD,
    audkenni: p.SKIKI_AUDK,
  };
};

export const resetClickUpdates = () => {
  return {
    landeign: undefined,
    umAfmorkun: undefined,
    selectedLandeignarnumer: undefined,
    skikar: undefined,
    stadfong: undefined,
    eigendur: undefined,
    isLoading: false,
    isNavOpen: false,
    toolsOpen: false,
    mLength: false,
    mArea: false,
    mAngle: false,
  };
};
