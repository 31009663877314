import { useContext } from 'react';

import { UIContext } from '../../context/ui';
import Icon from '../icon/Icon';

import s from './Zoom.module.scss';

export default function Zoom() {
  const { uiState } = useContext(UIContext);
  const { theMap } = uiState;

  const onClick = (type: string) => {
    if (!theMap) {
      return;
    }

    const view = theMap.getView();
    const zoomLevel = view.getZoom();

    if (!zoomLevel) {
      return;
    }

    view.animate({ zoom: type === 'in' ? zoomLevel + 1 : zoomLevel - 1 });
  };

  return (
    <div className={s.zoom}>
      <button
        className={s.zoom__in}
        onClick={() => {
          onClick('in');
        }}
        tabIndex={0}
      >
        <Icon icon="zoomin" />
      </button>
      <button
        className={s.zoom__out}
        onClick={() => {
          onClick('out');
        }}
        tabIndex={0}
      >
        <Icon icon="zoomout" />
      </button>
    </div>
  );
}
