import { LandeignWMS } from '../types/apiTypes';

import { showAlert } from './show-alert';
import { apiServiceUrl } from './urlUtils';

export async function fetchSelectedLandeignByCoordinate(url: string) {
  const apiUrl = apiServiceUrl(`api/landeign/geom`);
  try {
    const res: Response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(url),
    });

    //Svarið við kallinu sem safn af öllum þeim geometríum sem innihalda hnitið sem smellt var á
    const selectedLandeign: LandeignWMS | undefined = await res.json();
    if (!selectedLandeign) {
      showAlert('Gat ekki sótt gögn');
      return;
    }

    return selectedLandeign;
  } catch (e) {
    console.warn('unable to fetch geom', apiUrl, e);
  }
}

export async function fetchSelectedLandeignByLandeignNr(landeignNr: number) {
  const apiUrl = apiServiceUrl(`api/landeign/geom/${landeignNr}`);
  try {
    const res: Response = await fetch(apiUrl, {
      method: 'GET',
    });

    //Svarið við kallinu sem safn af öllum þeim geometríum sem innihalda hnitið sem smellt var á
    const selectedLandeign: LandeignWMS | undefined = await res.json();

    if (!selectedLandeign) {
      showAlert('Gat ekki sótt gögn');
      return;
    }

    return selectedLandeign;
  } catch (e) {
    console.warn('unable to fetch geom', apiUrl, e);
  }
}
