/* eslint-disable no-console */
import { Coordinate } from 'ol/coordinate';

import { addPolygonToLayer } from './ol-geometry-helpers';

export type SkikiProperties = {
  skiki?: string;
  selected?: boolean;
};

// Some coordinates are one level too deep
export const handleMultiPolygon = (
  coordinates: Array<Array<Array<Coordinate>>> | Array<Array<Coordinate>>,
  properties?: SkikiProperties
) => {
  if (typeof coordinates[0][0][0] === 'number') {
    return coordinates as Array<Array<Coordinate>>;
  } else {
    coordinates.forEach((coordinate) => {
      addPolygonToLayer(coordinate as Array<Array<Coordinate>>, properties);
    });
  }
};
