import { useContext, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import debounce from '@hugsmidjan/qj/debounce';

import { WindowType } from '../../containers/ToolsContainer';
import { UIContext } from '../../context/ui';
import { colorSecondary } from '../../open-layers/utils/colors';
import { SearchLiteItem } from '../../types/apiTypes';
import { getLandeignFromSearchedLandeign } from '../../utils/getLandeignFromSearchedLandeign';
import { getSveitarfelag } from '../../utils/getSveitarfelag';
import { searchLandeign } from '../../utils/searchLandeign';
import { setPath } from '../../utils/setPath';
import { showAlert } from '../../utils/show-alert';

import s from './Search.module.scss';

type Props = {
  changeWindow: (change: WindowType) => void;
};

type ParsedSearchItem = {
  landeignarnumer: number;
  name: string;
  searchItem: SearchLiteItem;
};

export default function Search({ changeWindow }: Props) {
  const [input, setInput] = useState<string>('');
  const [results, setResults] = useState<Array<ParsedSearchItem>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { uiState, setUiState } = useContext(UIContext);
  const { theMap } = uiState;

  const timeOut = 500;

  const checkInput = (value: string) => {
    setResults([]);
    if (value.length < 50) {
      setInput(value);
    }
  };

  const parseItems = (items: Array<SearchLiteItem>) => {
    const parsedItems = items.map((item) => {
      const splitItem = item.heiti.split(') - ');
      return {
        landeignarnumer: item.landeignNumer,
        name: `${splitItem[0]}`,
        searchItem: item,
      };
    });
    return parsedItems;
  };

  const doneTyping = async () => {
    if (input.length > 3) {
      setLoading(true);
      let item = await searchLandeign(input);

      if (!item) {
        showAlert('Gat ekki leitað');
        item = [];
      }
      const parsedItems = parseItems(item);
      setResults(parsedItems);
    }
    setLoading(false);
  };

  const onKeyUp = debounce(doneTyping, timeOut);

  const onClick = async (item: SearchLiteItem) => {
    if (!theMap) {
      return;
    }

    const landeignInfo = await getLandeignFromSearchedLandeign(item, theMap);
    setResults([]);
    setInput('');
    changeWindow(false);
    if (landeignInfo) {
      setUiState({
        landeign: landeignInfo.landeign,
        umAfmorkun: landeignInfo.umAfmorkun,
        selectedLandeignarnumer: landeignInfo.selectedLandeignarnumer,
        toolsOpen: false,
      });
      setPath(landeignInfo.selectedLandeignarnumer);
    } else {
      setUiState({
        landeign: {
          audkenni: `L${item.landeignNumer}`,
          sveitarfelag: getSveitarfelag(`${item.sveitarfelagsNumer}`),
        },
        selectedLandeignarnumer: item.landeignNumer,
      });
      setPath(item.landeignNumer);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doneTyping();
  };

  const onKeyDown = (e: React.KeyboardEvent, searchItem: SearchLiteItem) => {
    const key = e.key;
    if (key === 'Enter') {
      onClick(searchItem);
    } else if (key === 'ArrowDown') {
      const element = document.getElementById('root');
      element &&
        element.dispatchEvent(
          new KeyboardEvent('arrowDown', {
            key: 'tab',
          })
        );
    }
  };

  return (
    <div className={s.search}>
      <div className={s.search__searchBar}>
        <form className={s.search__form} action="post" onSubmit={onSubmit}>
          <input
            className={s.search__input}
            onChange={(e) => checkInput(e.target.value)}
            value={input}
            onKeyUp={onKeyUp}
            type="text"
            placeholder="Landeignanúmer eða staðfang"
            autoFocus
            maxLength={50}
          />
        </form>
        {loading && (
          <div className={s.search__loading}>
            <ClipLoader size={'20px'} color={colorSecondary} css="align-self: center" />
          </div>
        )}
      </div>
      {results.length > 0 && (
        <div className={s.search__searchResults}>
          <ul className={s.search__list}>
            {results.map((result, i) => {
              const sveitarfelag = result.searchItem.sveitarfelagNafn;
              return (
                <li
                  key={i}
                  className={s.search__listItem}
                  onClick={() => onClick(result.searchItem)}
                  onKeyDown={(e) => {
                    onKeyDown(e, result.searchItem);
                  }}
                  tabIndex={0}
                >
                  <p className={s.search__landeignarnumer}>L{result.landeignarnumer} </p>
                  <p className={s.search__name}>{result.name}</p>
                  {sveitarfelag && (
                    <p className={s.search__postnumer}>({sveitarfelag})</p>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
