import MobileLogo from '../../components/mobile-logo/MobileLogo';
import LandupplysingarContainer from '../../containers/LandupplysingarContainer';
import MapContainer from '../../containers/MapContainer';
import ToolsContainer from '../../containers/ToolsContainer';

import s from './Home.module.scss';

export default function Home() {
  return (
    <div className={s.home} id="home">
      <MobileLogo />
      <MapContainer />
      <ToolsContainer />
      <LandupplysingarContainer />
    </div>
  );
}
