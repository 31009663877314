import { ClipLoader } from 'react-spinners';

import { UiEigendur } from '../../context/ui';
import { colorSecondary } from '../../open-layers/utils/colors';
import AttentionBox from '../atention-box/AttentionBox';
import CountBubble from '../count-bubble/CountBubble';
import InfoHeading from '../info-heading/InfoHeading';

import s from './Landupplysingar.module.scss';

type Props = {
  eigendur?: Array<UiEigendur | undefined>;
  eigendurLoading?: boolean;
  eigendurError?: boolean;
};

export default function LandupplysingarEigendur({
  eigendur,
  eigendurLoading,
  eigendurError,
}: Props) {
  return (
    <div className={s.landUpplysingar__inner}>
      <InfoHeading
        id="eigendur"
        heading="Eigendur"
        info={true}
        infoText1="Vinsamlega hafið samband við viðkomandi sýslumannsembætti vegna athugasemda eða leiðréttinga."
        countBubble={
          eigendur && eigendur.length > 0 && <CountBubble count={eigendur.length} />
        }
      />
      <ul className={s.landUpplysingar__listEigendur}>
        {eigendurLoading ? (
          <li className={s.landUpplysingar__listItem}>
            <ClipLoader size={'20px'} color={colorSecondary} css="align-self: center" />
          </li>
        ) : eigendur && eigendur.length > 0 ? (
          eigendur.map((eigandi, i) => {
            if (!eigandi) {
              return null;
            }
            return (
              <li className={s.landUpplysingar__listItem} key={i}>
                <p className={s.landUpplysingar__listContent}>{eigandi.name}</p>
                <p className={s.landUpplysingar__listContent}>{eigandi.ratioOwned}%</p>
              </li>
            );
          })
        ) : eigendurError ? (
          <li className={s.landUpplysingar__attentionBox}>
            <AttentionBox richText={<p>Ekki tókst að sækja gögn um eigendur.</p>} />
          </li>
        ) : (
          <li className={s.landUpplysingar__attentionBox}>
            <AttentionBox
              richText={
                <p>
                  Eignarheimild landeiganda vantar, hefur ekki verið þinglýst, ekki færð
                  inn á eignina eða er ófullnægjandi.
                </p>
              }
            />
          </li>
        )}
      </ul>
    </div>
  );
}
