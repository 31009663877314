import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';

import { LAYER_LANDEIGNIR } from '../open-layers/layers/layers';

const apiBase = process.env.REACT_APP_API_BASE;

/**
 * Resolve URL for API calls.
 *
 * @param path Path for API call
 * @returns Resolved URL for API service call
 */
export function apiServiceUrl(path: string): string {
  try {
    const url = new URL(path, apiBase);
    return url.href;
  } catch (e) {
    console.warn('unable to construct api url', e);
  }

  return path;
}

export const makeFeatureInfoUrl = (map: Map, coordinate: Coordinate) => {
  const res = map.getView().getResolution();
  const src = LAYER_LANDEIGNIR.getSource();
  return (
    res &&
    src?.getFeatureInfoUrl(coordinate, res, 'EPSG:3057', {
      INFO_FORMAT: 'application/json',
    })
  );
};
