import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import {
  colorBlack,
  colorBlue,
  colorPrimary,
  colorPrimarySeathrough,
  colorRed,
  colorSkikiHoverFill,
  colorSkikiSelectedBorder,
} from './colors';

// Default skiki color
export const skikarStyle = new Style({
  fill: new Fill({
    color: colorPrimarySeathrough,
  }),
  stroke: new Stroke({
    color: colorPrimary,
    width: 3,
  }),
});

// Selected skiki
export const skikarSelectedStyle = new Style({
  fill: new Fill({
    color: colorPrimarySeathrough,
  }),
  stroke: new Stroke({
    color: colorSkikiSelectedBorder,
    width: 4,
  }),
});

// Skiki that is being hovered
export const skikarHoverStyle = new Style({
  fill: new Fill({
    color: colorSkikiHoverFill,
  }),
  stroke: new Stroke({
    color: colorPrimary,
    width: 3,
  }),
});

// Skiki that is selected and being hovered
export const skikarHoverAndSelectedStyle = new Style({
  fill: new Fill({
    color: colorSkikiHoverFill,
  }),
  stroke: new Stroke({
    color: colorSkikiSelectedBorder,
    width: 4,
  }),
});

export const stadfangStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: colorRed,
    }),
    stroke: new Stroke({
      color: colorBlack,
      width: 2,
    }),
  }),
});

export const selectedStadfangStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: colorBlue,
    }),
    stroke: new Stroke({
      color: colorPrimary,
      width: 2,
    }),
  }),
});

// Measurements in progres
export const segmentStyle = new Style({
  fill: new Fill({
    color: colorPrimarySeathrough,
  }),
  stroke: new Stroke({
    color: colorPrimary,
    lineDash: [5, 5],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: colorPrimary,
    }),
    fill: new Fill({
      color: colorPrimarySeathrough,
    }),
  }),
});

// Finished measurments
export const segmentStyleDone = new Style({
  fill: new Fill({
    color: colorPrimarySeathrough,
  }),
  stroke: new Stroke({
    color: colorPrimary,
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: colorPrimary,
    }),
    fill: new Fill({
      color: colorPrimarySeathrough,
    }),
  }),
});
