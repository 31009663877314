import $ from 'jquery';
import { GeoJSON } from 'ol/format';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { bbox } from 'ol/loadingstrategy';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import TileGrid from 'ol/tilegrid/TileGrid';

import { bbox as BBOX, resolutions } from '../utils/constants';
import {
  segmentStyleDone,
  selectedStadfangStyle,
  skikarStyle,
  stadfangStyle,
} from '../utils/styles';

//WMS Layer af afmörkunum á póstnúmerum landsins
//Sótt til LMÍ í gegnum geoserverinn þeirra
export const LAYER_POSTNUMER = new TileLayer({
  source: new TileWMS({
    url: 'https://gis.lmi.is/geoserver/wms',
    params: {
      LAYERS: 'postnumer',
      FORMAT: 'image/png',
      TILED: true,
      SRS: 'EPSG:3057',
      STYLES: 'postnumer_HMS',
    },
    tileGrid: new TileGrid({
      origin: [0, 0],
      resolutions: resolutions,
      tileSize: 512,
    }),
  }),
  visible: false,
});

//WMS Layer af afmörkunum á sveitarfélögum landsins
//Sótt til LMÍ í gegnum geoserverinn þeirra
export const LAYER_SVEITARFELOG = new TileLayer({
  source: new TileWMS({
    url: 'https://gis.lmi.is/geoserver/wms',
    params: {
      LAYERS: 'IS_50V:mork_sveitarf_flakar',
      FORMAT: 'image/png',
      TILED: true,
      SRS: 'EPSG:3057',
      STYLES: 'IS_50V:sveitarfelog_HMS',
    },
    tileGrid: new TileGrid({
      origin: [0, 0],
      resolutions: resolutions,
      tileSize: 512,
    }),
  }),
  visible: false,
});

export const LAYER_NYTJALAND = new TileLayer({
  source: new TileWMS({
    url: 'https://geo.fasteignaskra.is/ws/geoserver/wms',
    params: {
      LAYERS: 'public:NYTJALAND2006',
      FORMAT: 'image/png',
      TILED: true,
      SRS: 'EPSG:3057',
      STYLE: 'public:Vefsja_Nytjaland',
    },
    tileGrid: new TileGrid({
      origin: [0, 0],
      resolutions: resolutions,
      tileSize: 512,
    }),
  }),
  visible: false,
});

export const LAYER_VEGIR = new TileLayer({
  source: new TileWMS({
    url: 'http://gis.lmi.is/geoserver/wms',
    params: {
      LAYERS: 'IS_50V:samgongur_linur',
      FORMAT: 'image/png',
      TILED: true,
      SRS: 'EPSG:3057',
      STYLE: 'LMI_Grunnkort_samgongur_nyr',
    },
  }),
});

//WMS Layer af afmörkunum á skráðum landeignum á landinu
//Sótt til niður í núverandi Vefsjár GeoServer
export const LAYER_LANDEIGNIR = new TileLayer({
  className: 'LNR_BASE',
  source: new TileWMS({
    url: 'https://geo.fasteignaskra.is/ws/geoserver/wms',
    params: {
      LAYERS: 'fasteignaskra:MV_LANDEIGNASKRA',
      FORMAT: 'image/png',
      TILED: true,
      SRS: 'EPSG:3057',
      JSONP: false,
    },
    tileGrid: new TileGrid({
      origin: [0, 0],
      resolutions: resolutions,
      tileSize: 512,
    }),
  }),
  extent: [0, 0, 1048576, 1048576],
  visible: true,
});

// Hæðarlínur frá lmi - Slóð hefur ekki virkað hingað til...
export const LAYER_HAEDARLINUR = new TileLayer({
  className: 'LNR_BASE',
  source: new TileWMS({
    url: 'https://gis.lmi.is/geoserver/INSPIRE/wfs?request=GetCapabilities&service=WFS',
    params: {
      LAYERS: 'fasteignaskra:MV_LANDEIGNASKRA',
      FORMAT: 'image/png',
      TILED: true,
      SRS: 'EPSG:3057',
      JSONP: false,
    },
    tileGrid: new TileGrid({
      origin: [0, 0],
      resolutions: resolutions,
      tileSize: 512,
    }),
  }),
  extent: [0, 0, 1048576, 1048576],
  visible: true,
});

//WFS Layer af skráðum staðföngum á landinu
//Sótt til niður í núverandi Vefsjár GeoServer
//Vegna þess að þetta er Geometry item er ekki hægt að nota millilag til að
//sækja þetta og því verður kallið í þjónustuna aðens flóknara en eðlilegt er
export const SOURCE_STADFONG = new VectorSource({
  loader: function (extent) {
    const callbackName =
      'vefsja_jsonp_callback' + Date.now() + Math.floor(Math.random() * 100000);
    const url = `https://geo.fasteignaskra.is/ws/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=fasteignaskra:VSTADF_ALLT&outputFormat=text/javascript&format_options=callback:${callbackName}&srsname=EPSG:3057&bbox=${extent.join(
      ','
    )},EPSG:3057`;
    $.ajax({
      url: url,
      dataType: 'jsonp',
      jsonp: false,
      jsonpCallback: callbackName,
      success: function (result) {
        const gj = new GeoJSON();
        const features = gj.readFeatures(result);
        SOURCE_STADFONG.addFeatures(features);
      },
    }).fail(function (error) {
      console.error(`Eitthvað fór úskeiðis: ${error.responseText}`);
    });
  },
  strategy: bbox,
});

export const LAYER_STADFONG = new VectorLayer({
  className: 'LNR_BASE_WFS',
  visible: true,
  maxResolution: resolutions[11],
  extent: BBOX,
  style: stadfangStyle,
  source: SOURCE_STADFONG,
});

export const SOURCE_SELECTED_LANDEIGN = new VectorSource();

//WMS Vector layer sem birtir alla skika sem tilheyra valinni landeign
export const LAYER_SELECTED_LANDEIGN = new VectorLayer({
  className: 'WMS_SELECTED',
  source: SOURCE_SELECTED_LANDEIGN,
  visible: true,
  style: [skikarStyle],
});

//Source og Layer fyrir teiknivirkni kerfisins
export const SOURCE_DRAW = new VectorSource();

export const DRAW_LAYER = new VectorLayer({
  className: 'DRAW_LAYER',
  style: segmentStyleDone,
  source: SOURCE_DRAW,
  visible: true,
});

export const SOURCE_SELECTED_STADFONG = new VectorSource();

//WMS Vector layer sem birtir staðfang á landeign/staðfangi sem leitað var eftir
export const LAYER_SELECTED_STADFONG = new VectorLayer({
  className: 'SEARCHED_STADFANG',
  source: SOURCE_SELECTED_STADFONG,
  visible: true,
  style: selectedStadfangStyle,
});
