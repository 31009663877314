import s from './CountBubble.module.scss';

type Props = {
  count: number;
};

export default function CountBubble({ count }: Props) {
  return (
    <div className={s.countBubble}>
      <p className={s.countBubble__count}>{count}</p>
    </div>
  );
}
