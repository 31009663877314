import { useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { UIContext } from '../../context/ui';
import { toggleLayers } from '../../open-layers/utils/ol-map-utils';
import useWindowDimensions from '../../utils/useWindowDimentions';
import Checkbox from '../form-components/Checkbox';
import Icon from '../icon/Icon';

import s from './Layers.module.scss';

export default function Layers() {
  const { viewportWidth } = useWindowDimensions();
  const { uiState, setUiState } = useContext(UIContext);
  const { externalLayer } = uiState;

  // Handle user changes to layer visibility
  useEffect(() => {
    if (externalLayer) {
      toggleLayers(externalLayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalLayer]);

  const onChange = (value: string) => {
    setUiState({
      externalLayer: {
        landeignir:
          value === 'landeignir'
            ? !externalLayer?.landeignir
            : externalLayer?.landeignir ?? false,
        stadfong:
          value === 'stadfong'
            ? !externalLayer?.stadfong
            : externalLayer?.stadfong ?? false,
        postnumer:
          value === 'postnumer'
            ? !externalLayer?.postnumer
            : externalLayer?.postnumer ?? false,
        sveitarfelog:
          value === 'sveitarfelog'
            ? !externalLayer?.sveitarfelog
            : externalLayer?.sveitarfelog ?? false,
        nytjaland2006:
          value === 'nytjaland2006'
            ? !externalLayer?.nytjaland2006
            : externalLayer?.nytjaland2006 ?? false,
        vegir: value === 'vegir' ? !externalLayer?.vegir : externalLayer?.vegir ?? false,
      },
    });
  };

  return (
    <div className={s.layers}>
      <h2 className={s.layers__title}>Kortaþekjur</h2>
      <form className={s.layers__form} action="post">
        <div className={s.layers__checkboxWrapper}>
          <Checkbox
            label="Landeignir"
            id="landeignir"
            name="landeignir"
            value="landeignir"
            checked={externalLayer?.landeignir}
            onChange={onChange}
          />
        </div>
        <div className={s.layers__checkboxWrapper}>
          <Checkbox
            label="Staðföng"
            id="stadfong"
            name="stadfong"
            value="stadfong"
            checked={externalLayer?.stadfong}
            onChange={onChange}
          />
          <a className={s.layers__tooltipAnchor} data-tip data-for={'stadfong'}>
            <div className={s.layers__iconWrapper}>
              <Icon icon="info" />
            </div>
          </a>
        </div>
        <ReactTooltip
          id={'stadfong'}
          className={s.infoHeading__tooltip}
          place={viewportWidth > 700 ? 'right' : 'bottom'}
          effect={'solid'}
          type={'dark'}
        >
          <div className={s.layers__tooltipWrapper}>
            <p className={s.layers__infoText}>
              Staðfangaþekja er aðeins sýnileg þegar þysjað hefur verið inn á kortinu
            </p>
          </div>
        </ReactTooltip>
        <div className={s.layers__checkboxWrapper}>
          <Checkbox
            label="Póstnúmer"
            id="postalCodes"
            name="postalCodes"
            value="postnumer"
            checked={externalLayer?.postnumer}
            onChange={onChange}
          />
        </div>
        <div className={s.layers__checkboxWrapper}>
          <Checkbox
            label="Sveitarfélög"
            id="municipalities"
            name="municipalities"
            value="sveitarfelog"
            checked={externalLayer?.sveitarfelog}
            onChange={onChange}
          />
        </div>
        <div className={s.layers__checkboxWrapper}>
          <Checkbox
            label="Nytjaland"
            id="nytjaland2006"
            name="nytjaland2006"
            value="nytjaland2006"
            checked={externalLayer?.nytjaland2006}
            onChange={onChange}
          />
          <a className={s.layers__tooltipAnchor} data-tip data-for={'nytjaland'}>
            <div className={s.layers__iconWrapper}>
              <Icon icon="info" />
            </div>
          </a>
        </div>
        <ReactTooltip
          id={'nytjaland'}
          className={s.infoHeading__tooltip}
          place={viewportWidth > 700 ? 'right' : 'bottom'}
          effect={'solid'}
          type={'dark'}
        >
          <div className={s.layers__tooltipWrapper}>
            <p className={s.layers__infoText}>
              Athugið að línurnar hafa enga lagalega þýðingu og ber aðeins að taka þeim
              sem vísbendingu fyrir frekari heimildaöflun. Þinglýst landamerkjabréf jarða
              má nálgast hjá viðkomandi sýslumannsembætti.
            </p>
          </div>
        </ReactTooltip>
        <div className={s.layers__checkboxWrapper}>
          <Checkbox
            label="Vegir"
            id="roads"
            name="roads"
            value="vegir"
            checked={externalLayer?.vegir}
            onChange={onChange}
          />
        </div>
      </form>
    </div>
  );
}
