// Functions that regard the map it self or layer visibility

import { Map } from 'ol';
import { Attribution, defaults as defaultControls, ScaleLine } from 'ol/control';
import { noModifierKeys } from 'ol/events/condition';
import { defaults as defaultInteractions, DragPan } from 'ol/interaction';
import { Projection } from 'ol/proj';
import View from 'ol/View';

import { BaseMap, LayerToggle } from '../../types/data';
import { BingMap, Kortmynd, OpenStreetMap } from '../layers/baseMaps';
import {
  DRAW_LAYER,
  LAYER_LANDEIGNIR,
  LAYER_NYTJALAND,
  LAYER_POSTNUMER,
  LAYER_SELECTED_LANDEIGN,
  LAYER_SELECTED_STADFONG,
  LAYER_STADFONG,
  LAYER_SVEITARFELOG,
  LAYER_VEGIR,
} from '../layers/layers';

import { bbox } from './constants';

// Scale, shown in the bottom left corner
const scaleLine = new ScaleLine({
  units: 'metric',
});

// Tag to attribute where the map is from. Also in the bottom left
const attribution = new Attribution({
  collapsible: false,
});

//Sækir tómt kort með 'map' sem target
export const getEmptyMap = () => {
  return new Map({
    target: 'map',
    interactions: defaultInteractions({ dragPan: true }).extend([
      new DragPan({
        condition: function (mapBrowserEvent) {
          return noModifierKeys(mapBrowserEvent);
        },
      }),
    ]),
    controls: defaultControls({ attribution: false }).extend([scaleLine, attribution]),
    keyboardEventTarget: document,
  });
};

export const getMap = (ISN93: Projection, viewportWidth: number) => {
  const newMap = getEmptyMap();
  newMap.setLayers([Kortmynd, OpenStreetMap, BingMap]);
  newMap.setView(
    new View({
      center: viewportWidth > 700 ? [500000, 500000] : [350000, 520000],
      extent: bbox,
      zoom: 8,
      minZoom: 8,
      maxZoom: 23,
      projection: ISN93,
    })
  );

  return newMap;
};

export const addLayersToMap = (map: Map) => {
  map.addLayer(LAYER_LANDEIGNIR);
  map.addLayer(LAYER_POSTNUMER);
  map.addLayer(LAYER_SVEITARFELOG);
  map.addLayer(LAYER_NYTJALAND);
  map.addLayer(LAYER_VEGIR);
  map.addLayer(LAYER_SELECTED_LANDEIGN);
  map.addLayer(LAYER_STADFONG);
  map.addLayer(LAYER_SELECTED_STADFONG);
  map.addLayer(DRAW_LAYER);

  LAYER_POSTNUMER.setVisible(false);
  LAYER_SVEITARFELOG.setVisible(false);
  LAYER_NYTJALAND.setVisible(false);
  LAYER_VEGIR.setVisible(false);
};

export const changeBaseMap = (layers: BaseMap) => {
  Kortmynd.setVisible(layers.kortamynd);
  OpenStreetMap.setVisible(layers.osm);
  BingMap.setVisible(layers.bingMaps);
};

export const toggleLayers = (layers: LayerToggle) => {
  LAYER_LANDEIGNIR.setVisible(layers.landeignir);
  LAYER_STADFONG.setVisible(layers.stadfong);
  LAYER_POSTNUMER.setVisible(layers.postnumer);
  LAYER_SVEITARFELOG.setVisible(layers.sveitarfelog);
  LAYER_NYTJALAND.setVisible(layers.nytjaland2006);
  LAYER_VEGIR.setVisible(layers.vegir);
};
