/* eslint-disable complexity */
import { useContext, useEffect, useState } from 'react';
import { Map, Overlay } from 'ol';
import Feature from 'ol/Feature';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { MapComponent } from '../components/map/Map';
import { UIContext, UIState } from '../context/ui';
import { handleMapClick } from '../open-layers/utils/ol-click-utils';
import { getMap } from '../open-layers/utils/ol-map-utils';
import {
  skikarHoverAndSelectedStyle,
  skikarHoverStyle,
  skikarSelectedStyle,
} from '../open-layers/utils/styles';
import { StadfangFeature } from '../types/apiTypes';
import { uiSkikiToUmAfmorkun } from '../utils/convertion';
import { getISN93Projection } from '../utils/fetchProjection';
import useWindowDimensions from '../utils/useWindowDimentions';

// Trigger skikar fetch when the landeign in the context changes
export default function MapContainer() {
  const [map, setMap] = useState<Map>();
  const [clickUpdates, setClickUpdates] = useState<UIState>();
  const [updateUmAfmorkun, setUpdateUmAfmorkun] = useState<string | undefined>();
  const { uiState, setUiState } = useContext(UIContext);
  const { mapClickOn, skikar } = uiState;
  const { viewportWidth, viewportHeight } = useWindowDimensions();

  // Initialize map
  useEffect(() => {
    getISN93Projection().then((val) => {
      if (val != null) {
        const map = getMap(val, viewportWidth);
        setMap(map);
        setUiState({ theMap: map });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update UI state if map click was on a feature
  useEffect(() => {
    if (!updateUmAfmorkun || !skikar) {
      return;
    }

    skikar.skikar?.forEach((skiki) => {
      if (skiki.skiki === updateUmAfmorkun) {
        setUiState(uiSkikiToUmAfmorkun(skiki));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUmAfmorkun]);

  // Update UI states after map click
  useEffect(() => {
    if (!mapClickOn || !clickUpdates) {
      // If tools are active, we don't wan't to change the map at all
      return;
    }
    setUiState(clickUpdates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickUpdates]);

  const mapClickAction = async (e: MapBrowserEvent<MouseEvent>) => {
    if (!map) {
      return;
    }

    const { skiki, info } = await handleMapClick(map, e, viewportWidth, viewportHeight);
    info && setClickUpdates(info);
    skiki && setUpdateUmAfmorkun(skiki);
  };

  const addPopupAction = (map: Map) => {
    const element = document.getElementById('popup');

    const popup = new Overlay({
      element: element ?? undefined,
      positioning: 'bottom-center',
      stopEvent: false,
    });
    map.addOverlay(popup);

    const clearPopUp = () => {
      popup.setPosition([-1, -1]);
      setUiState({
        landeignPopover: {
          title: undefined,
          landeignarnumer: undefined,
          n: undefined,
          w: undefined,
        },
      });
    };

    // Popup bubble on hover
    map.on('pointermove', (e) => {
      const feature = map.forEachFeatureAtPixel(e.pixel, (feature) => {
        const featureType = feature.getGeometry()?.getType();
        const selectedStadfang = feature.get('selectedStadfang');
        if (featureType === 'Polygon' || selectedStadfang) {
          return;
        }

        const values = (feature as unknown as StadfangFeature).values_;
        let title, landeignarnumer, n, w;
        if (values && values.HEITI_NF) {
          title = `${values.HEITI_NF} ${values.HUSMERKING ?? ''} ${values.VIDSK ?? ''} ${
            values.SERHEITI ? `(${values.SERHEITI})` : ''
          }`;
          landeignarnumer = values.LANDNR;
          const flatCoordinates = values.geometry?.flatCoordinates;
          n = flatCoordinates ? flatCoordinates[0] : 0;
          w = flatCoordinates ? flatCoordinates[1] : 0;
        } else {
          clearPopUp();
        }

        if (title || landeignarnumer || n || w) {
          popup.setPosition(e.coordinate);

          setUiState({
            landeignPopover: {
              title,
              landeignarnumer,
              n: n ? Math.round(n * 10000) / 10000 : undefined,
              w: w ? Math.round(w * 10000) / 10000 : undefined,
            },
          });
        }
        return feature;
      });

      if (!feature) {
        clearPopUp();
      }
    });

    // Close the popup when the map is moved
    map.on('moveend', () => {
      clearPopUp();
    });
  };

  const addLandeignHover = (map: Map) => {
    let hoverFeature: Feature | null = null;
    map.on('pointermove', (e) => {
      if (hoverFeature !== null) {
        const selected = hoverFeature.get('selected');
        if (selected) {
          hoverFeature.setStyle(skikarSelectedStyle);
        } else {
          hoverFeature.setStyle(undefined);
        }
        hoverFeature.setProperties({ hover: false });
        hoverFeature = null;
      }

      map.forEachFeatureAtPixel(e.pixel, (f) => {
        hoverFeature = f as unknown as Feature;
        const featureType = hoverFeature.getGeometry()?.getType();
        const skiki = hoverFeature.get('skiki');
        if (featureType === 'Point' || !skiki) {
          return;
        }

        const selected = hoverFeature.get('selected');
        if (selected) {
          hoverFeature.setStyle(skikarHoverAndSelectedStyle);
        } else {
          hoverFeature.setStyle(skikarHoverStyle);
        }
        hoverFeature.setProperties({ hover: true });
        return true;
      });
    });
  };

  return (
    <MapComponent
      addPopupAction={addPopupAction}
      addLandeignHover={addLandeignHover}
      mapClickAction={mapClickAction}
      map={map}
    />
  );
}
