import s from './Radio.module.scss';

type Props = {
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  checked?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
};

export default function Radio({ id, name, value, label, checked, onChange }: Props) {
  return (
    <div className={s.radio}>
      <input
        className={s.radio__input}
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <div className={s.radio__checkmarkWrapper}>
        <div className={checked ? s.radio__checkmark : s.radio__unchecked} />
      </div>
      <label className={s.radio__label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
