export const colorPrimary = '#11223a';
export const colorPrimarySeathrough = 'rgba(17, 34, 58, 0.3)';
export const colorSecondary = '#92b1b0';
export const colorTertiary = '#b39271';
export const colorGold = '#b39271';
export const colorRed = '#e25e5c';
export const colorBlue = '#7de8e7';
export const colorBlack = '#000000';
export const colorSkikiSelectedBorder = '#8cd9d8';
export const colorSkikiHoverFill = 'rgba(140, 217, 216, 0.3)';
