import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Map } from 'ol';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { UIContext } from '../../context/ui';
import {
  LAYER_SELECTED_LANDEIGN,
  SOURCE_SELECTED_LANDEIGN,
  SOURCE_STADFONG,
} from '../../open-layers/layers/layers';
import { specialLandeignarnumer } from '../../open-layers/utils/constants';
import { addPolygonToLayer } from '../../open-layers/utils/ol-geometry-helpers';
import { addLayersToMap } from '../../open-layers/utils/ol-map-utils';
import { StadfangFeature } from '../../types/apiTypes';
import { getLandeignFromSearchedLandeign } from '../../utils/getLandeignFromSearchedLandeign';
import { getSveitarfelag } from '../../utils/getSveitarfelag';
import { searchLandeign } from '../../utils/searchLandeign';

import './Map.scss';

type Props = {
  addPopupAction: (map: Map) => void;
  addLandeignHover: (map: Map) => void;
  mapClickAction: (e: MapBrowserEvent<MouseEvent>) => void;
  map?: Map;
};

export function MapComponent({
  addPopupAction,
  addLandeignHover,
  mapClickAction,
  map,
}: Props) {
  const [path, setPath] = useState<string>(window.location.pathname.replace('/', ''));
  const [init, setInit] = useState(true);
  const [initLandeignarnumer, setInitLandeignarnumer] = useState<number | undefined>();

  const { uiState, setUiState } = useContext(UIContext);
  const { landeignPopover, skikar, umAfmorkun, selectedLandeignarnumer } = uiState;

  const location = useLocation();

  useEffect(() => {
    const pathInUrl = location.pathname.replace('/', '');
    setPath(pathInUrl);
    if (init && !pathInUrl) {
      setInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Add stuff to map, once there is a map
  useEffect(() => {
    if (map) {
      addLayersToMap(map);
      addPopupAction(map);
      addLandeignHover(map);
      map.on('singleclick', mapClickAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // Add/remove skikis with the same landeignarnumer as the selected
  useEffect(() => {
    if (skikar) {
      SOURCE_SELECTED_LANDEIGN.clear();
      LAYER_SELECTED_LANDEIGN.setVisible(true);
      skikar.skikar?.forEach((skiki) => {
        if (!skiki.coordinates) {
          return;
        }

        if (
          selectedLandeignarnumer === specialLandeignarnumer &&
          skiki.skiki !== umAfmorkun?.audkenni
        ) {
          return;
        }

        addPolygonToLayer(skiki.coordinates, {
          skiki: skiki.skiki,
          selected: skiki.skiki === umAfmorkun?.audkenni,
        });
      });
    } else {
      SOURCE_SELECTED_LANDEIGN.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skikar]);

  // Find landeignarnumer on page load and select that landeign
  useEffect(() => {
    const searchByParams = async (path: string, map: Map) => {
      const item = await searchLandeign(path);

      if (!item || !item[0]) {
        return;
      }
      const landeignInfo = await getLandeignFromSearchedLandeign(item[0], map);

      if (landeignInfo) {
        setUiState({
          landeign: landeignInfo.landeign,
          umAfmorkun: landeignInfo.umAfmorkun,
          selectedLandeignarnumer: landeignInfo.selectedLandeignarnumer,
          toolsOpen: false,
        });
        setInit(false);
      } else {
        setUiState({
          landeign: {
            audkenni: `L${item[0].landeignNumer}`,
            sveitarfelag: getSveitarfelag(`${item[0].sveitarfelagsNumer}`),
          },
          selectedLandeignarnumer: item[0].landeignNumer,
        });

        // Todo, breyta þegar sveitarfélag kemur með leitinni
        setInitLandeignarnumer(item[0].landeignNumer);
      }
    };

    if (path && map) {
      searchByParams(path, map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, map]);

  // Only used when comming into the site for the first time with /<landeignarnumer>
  // for a landeign that doesn't have a defined boundary, just staðföng
  useEffect(() => {
    const features = SOURCE_STADFONG.getFeatures();
    let foundOne: boolean | undefined;
    if (features[0] && path && init) {
      features.forEach((feature) => {
        const values_ = (feature as unknown as StadfangFeature).values_;
        if (!foundOne && values_?.LANDNR === initLandeignarnumer) {
          foundOne = true;
          const landeignarnumer = values_?.LANDNR;
          setUiState({
            landeign: {
              audkenni: `L${landeignarnumer}`,
              sveitarfelag: getSveitarfelag(values_?.SVFNR),
            },
            selectedLandeignarnumer: landeignarnumer,
          });
        }
      });
      setInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SOURCE_STADFONG.getFeatures()]);

  return (
    <div id="map" className="map">
      <div className="popup" id="popup" data-tip data-tip-for="popup">
        {landeignPopover && (
          <>
            <div className="popup__content">
              <div className="popup__top">
                {landeignPopover.title && (
                  <h3 className="popup__title">{landeignPopover.title}</h3>
                )}
              </div>
              <div className="popup__bottom">
                {landeignPopover.landeignarnumer && (
                  <p className="popup__landeignarnumer">
                    L{landeignPopover.landeignarnumer}
                  </p>
                )}
                {landeignPopover.n && (
                  <p className="popup__coordinate">X {landeignPopover.n}</p>
                )}
                {landeignPopover.w && (
                  <p className="popup__coordinate">Y {landeignPopover.w}</p>
                )}
              </div>
            </div>
            <div className="popup__point" />
          </>
        )}
      </div>
    </div>
  );
}
