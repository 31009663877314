import { UiLandeign } from '../../context/ui';
import Area from '../area/Area';

import s from './Landupplysingar.module.scss';

type Props = {
  landeign?: UiLandeign | undefined;
};

export default function LandupplysingarLandeign({ landeign }: Props) {
  return (
    <div className={s.landUpplysingar__inner}>
      <ul className={s.landUpplysingar__list}>
        {landeign ? (
          <>
            {landeign.audkenni && (
              <li className={s.landUpplysingar__listItem}>
                <p className={s.landUpplysingar__listTitle}>Auðkenni</p>
                <p className={s.landUpplysingar__listContent}>{landeign.audkenni}</p>
              </li>
            )}
            {landeign.sveitarfelag && (
              <li className={s.landUpplysingar__listItem}>
                <p className={s.landUpplysingar__listTitle}>Sveitarfélag</p>
                <p className={s.landUpplysingar__listContent}>{landeign.sveitarfelag}</p>
              </li>
            )}
            {landeign.gerd && (
              <li className={s.landUpplysingar__listItem}>
                <p className={s.landUpplysingar__listTitle}>Gerð</p>
                <p className={s.landUpplysingar__listContent}>{landeign.gerd}</p>
              </li>
            )}
            <li className={s.landUpplysingar__listItem}>
              <p className={s.landUpplysingar__listTitle}>Skráð stærð</p>
              <p className={s.landUpplysingar__listContent}>
                {landeign.skradStaerd ? (
                  <Area size={landeign.skradStaerd} />
                ) : (
                  'Ekki skráð'
                )}
              </p>
            </li>

            <li className={s.landUpplysingar__listItem}>
              <p className={s.landUpplysingar__listTitle}>Mæld stærð</p>
              <p className={s.landUpplysingar__listContent}>
                {landeign.maeldStaerd ? (
                  <Area size={landeign.maeldStaerd} />
                ) : (
                  'Ekki mælt'
                )}
              </p>
            </li>
          </>
        ) : (
          <li className={s.landUpplysingar__listItem}>Engin landeign fannst</li>
        )}
      </ul>
    </div>
  );
}
