import { UmAfmorkun } from '../../context/ui';

import s from './Landupplysingar.module.scss';

type Props = {
  umAfmorkun?: UmAfmorkun;
};

export default function LandupplysingarUmAfmorkun({ umAfmorkun }: Props) {
  if (umAfmorkun) {
    return (
      <div className={s.landUpplysingar__inner}>
        <h2 className={s.landUpplysingar__title}>Um afmörkun {umAfmorkun.audkenni}</h2>
        <ul className={s.landUpplysingar__list}>
          {umAfmorkun.adferd && (
            <li className={s.landUpplysingar__listItem}>
              <p className={s.landUpplysingar__listTitle}>Aðferð skráningar</p>
              <p className={s.landUpplysingar__listContent}>{umAfmorkun.adferd}</p>
            </li>
          )}
          {umAfmorkun.nakvaemni && (
            <li className={s.landUpplysingar__listItem}>
              <p className={s.landUpplysingar__listTitle}>Nákvæmni (m)</p>
              <p className={s.landUpplysingar__listContent}>
                {umAfmorkun.nakvaemni !== '0' ? umAfmorkun.nakvaemni : 'Ekki vitað'}
              </p>
            </li>
          )}
          {umAfmorkun.heimild && (
            <li className={s.landUpplysingar__listItem}>
              <p className={s.landUpplysingar__listTitle}>Heimild</p>
              <p className={s.landUpplysingar__listContent}>{umAfmorkun.heimild}</p>
            </li>
          )}
          {umAfmorkun.athugasemd && (
            <li className={s.landUpplysingar__listItem}>
              <p className={s.landUpplysingar__listTitle}>Athugasemd</p>
              <p className={s.landUpplysingar__listContent}>{umAfmorkun.athugasemd}</p>
            </li>
          )}
        </ul>
      </div>
    );
  } else {
    return null;
  }
}
