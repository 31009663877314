import { Link } from 'react-router-dom';

import { ReactComponent as Hms } from '../../assets/svg/hms-logo.svg';
import { setPath } from '../../utils/setPath';

import s from './MobileLogo.module.scss';

export default function MobileLogo() {
  return (
    <div className={s.mobileLogo}>
      <Link to="/" className={s.mobileLogo__logo} tabIndex={0}>
        {/* <Icon icon="hms" wide onClick={() => setPath()} /> */}
        <Hms className={s.mobileLogo} onClick={() => setPath()} />
      </Link>
    </div>
  );
}
