import { getFormattedSize } from '../../utils/formats';

import s from './Area.module.scss';

type Props = {
  size: number;
};

export default function Area({ size }: Props) {
  const formattedSize = getFormattedSize(size);
  if (formattedSize) {
    return (
      <>
        {formattedSize.size} {formattedSize.prefix}
        {formattedSize.superscript && <sup className={s.area}>2</sup>}
      </>
    );
  } else {
    return null;
  }
}
