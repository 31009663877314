export function zoomLevelByArea(area: number) {
  if (area > 2500000000) {
    return 10;
  } else if (area > 650000000) {
    return 11;
  } else if (area > 250000000) {
    return 12;
  } else if (area > 100000000) {
    return 13;
  } else if (area > 20000000) {
    return 14;
  } else if (area > 3000000) {
    return 15;
  } else if (area > 400000) {
    return 16;
  } else if (area > 23500) {
    return 17;
  } else if (area > 20000) {
    return 18;
  } else {
    return 19;
  }
}
