import { ClipLoader } from 'react-spinners';

import { UiStadfong } from '../../context/ui';
import { colorSecondary } from '../../open-layers/utils/colors';
import AttentionBox from '../atention-box/AttentionBox';
import CountBubble from '../count-bubble/CountBubble';
import InfoHeading from '../info-heading/InfoHeading';

import s from './Landupplysingar.module.scss';

type Props = {
  stadfong?: UiStadfong | undefined;
  plainStadfong?: Array<string>;
  stadfongLoading?: boolean;
  stadfongError?: boolean;
};

export default function LandupplysingarStadfong({
  stadfong,
  plainStadfong,
  stadfongLoading,
  stadfongError,
}: Props) {
  return (
    <div className={s.landUpplysingar__inner}>
      <InfoHeading
        id="stadfong"
        heading="Staðföng"
        info={false}
        countBubble={
          stadfong &&
          stadfong.stadfong.length > 0 && <CountBubble count={stadfong.stadfong.length} />
        }
      />
      <ul className={s.landUpplysingar__listStadfong}>
        {stadfongLoading ? (
          <li className={s.landUpplysingar__listItem}>
            <ClipLoader size={'20px'} color={colorSecondary} css="align-self: center" />
          </li>
        ) : stadfong && stadfong.stadfong.length > 0 ? (
          stadfong.stadfong.map((stadfang, i) => (
            <li className={s.landUpplysingar__listItem} key={i}>
              <p className={s.landUpplysingar__listContent}>
                {`${stadfang.HEITI_NF} ${stadfang.HUSNR ?? ''} ${stadfang.BOKST ?? ''} ${
                  stadfang.VIDSK ?? ''
                } ${stadfang.SERHEITI ? '(' + stadfang.SERHEITI + ')' : ''}`}
              </p>
            </li>
          ))
        ) : plainStadfong ? (
          plainStadfong.map((stadfang, i) => (
            <li className={s.landUpplysingar__listItem} key={i}>
              <p className={s.landUpplysingar__listContent}>{stadfang}</p>
            </li>
          ))
        ) : stadfongError ? (
          <li className={s.landUpplysingar__attentionBox}>
            <AttentionBox richText={<p>Ekki tókst að sækja staðföng.</p>} />
          </li>
        ) : (
          <li className={s.landUpplysingar__attentionBox}>
            <AttentionBox richText={<p>Engin staðföng fundust á landeign.</p>} />
          </li>
        )}
      </ul>
    </div>
  );
}
