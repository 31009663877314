import { useContext, useEffect, useState } from 'react';

import Landupplysingar from '../components/landupplysingar/Landupplysingar';
import { UIContext } from '../context/ui';
import { SOURCE_SELECTED_STADFONG } from '../open-layers/layers/layers';
import { addPointToLayer } from '../open-layers/utils/ol-geometry-helpers';
import {
  gjaldendurToUiEigendur,
  skikarWmsToSkikar,
  stadfangFeaturesToUiStadfong,
} from '../utils/convertion';
import { ExcludesFalse } from '../utils/excludesFalse';
import {
  fetchGjaldandiByLandeignarnumer,
  fetchSkikarByLandeignarnumer,
  fetchStadfongByLandeignarnumer,
} from '../utils/fetchByLandeignarnumer';
import { searchLandeign } from '../utils/searchLandeign';

export default function LandupplysingarContainer() {
  const { uiState, setUiState } = useContext(UIContext);
  const { selectedLandeignarnumer } = uiState;
  const [skikarLoading, setSkikarLoading] = useState(true);
  const [skikarError, setSkikarError] = useState(true);
  const [stadfongLoading, setStadfongLoading] = useState(true);
  const [stadfongError, setStadfongError] = useState(false);
  const [eigendurLoading, setEigendurLoading] = useState(true);
  const [eigendurError, setEigendurError] = useState(false);
  const [plainStadfong, setPlainStadfong] = useState<Array<string> | undefined>();

  useEffect(() => {
    const getSkikar = async (nr: number) => {
      setSkikarLoading(true);
      const skikar = await fetchSkikarByLandeignarnumer(nr);

      if (skikar === undefined) {
        setSkikarLoading(false);
        setSkikarError(true);
        return;
      }

      setUiState({ skikar: skikarWmsToSkikar(skikar) });
      setSkikarLoading(false);
    };

    if (selectedLandeignarnumer) {
      getSkikar(selectedLandeignarnumer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLandeignarnumer]);

  // Trigger eigendur fetch when the landeign in the context changes
  useEffect(() => {
    const getGjaldendur = async (nr: number) => {
      setEigendurLoading(true);
      setEigendurError(false);
      const gjaldendur = await fetchGjaldandiByLandeignarnumer(nr);

      if (!gjaldendur) {
        setEigendurLoading(false);
        setEigendurError(true);
        setUiState({ eigendur: undefined });
        return;
      }

      setUiState({
        eigendur: gjaldendurToUiEigendur(gjaldendur),
      });
      setEigendurLoading(false);
    };

    if (selectedLandeignarnumer) {
      getGjaldendur(selectedLandeignarnumer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLandeignarnumer]);

  // Trigger stadföng fetch when the landeign in the context changes
  useEffect(() => {
    const getStadfong = async (nr: number) => {
      setStadfongLoading(true);
      SOURCE_SELECTED_STADFONG.clear();
      const stadfong = await fetchStadfongByLandeignarnumer(nr);

      if (!stadfong || !stadfong.length) {
        // If we get no staðföng from the geoserver, there might still be some
        // that don't have a coordinate and will show up in the search.
        const searchStadfong = await searchLandeign(`${nr}`);

        const filteredStadfong = searchStadfong
          ?.map((stadfang) => {
            if (stadfang.landeignNumer === nr) {
              return stadfang;
            }
          })
          .filter(Boolean as unknown as ExcludesFalse);

        setPlainStadfong(
          filteredStadfong?.map((stadfang) => {
            return `${stadfang.heiti} (Ekki staðsett)`;
          })
        );

        setStadfongLoading(false);
        setStadfongError(false);
        return;
      }

      setPlainStadfong(undefined);
      stadfong.forEach((stadfang) => {
        addPointToLayer(stadfang.geometry.coordinates);
      });

      setUiState({
        stadfong: stadfangFeaturesToUiStadfong(stadfong),
      });
      setStadfongLoading(false);
    };

    if (selectedLandeignarnumer) {
      getStadfong(selectedLandeignarnumer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLandeignarnumer]);

  return (
    <Landupplysingar
      skikarLoading={skikarLoading}
      skikarError={skikarError}
      stadfongLoading={stadfongLoading}
      stadfongError={stadfongError}
      plainStadfong={plainStadfong}
      eigendurLoading={eigendurLoading}
      eigendurError={eigendurError}
    />
  );
}
