import { Map } from 'ol';

import { SearchLiteItem } from '../types/apiTypes';

import { landeignWmsToUiLandeign, landeignWmsToUmAfmorkun } from './convertion';
import { fetchSelectedLandeignByLandeignNr } from './fetchSelectedLandeign';
import { setPath } from './setPath';
import { zoomLevelByArea } from './zoomLevelByArea';

export const getLandeignFromSearchedLandeign = async (item: SearchLiteItem, map: Map) => {
  const view = map.getView();
  const selectedLandeign = await fetchSelectedLandeignByLandeignNr(item.landeignNumer);

  if (!item.x || !item.y) {
    // There shouldn't be a landeign with afmörkun but not a staðfang with a coordinate
    view.animate({ center: [500000, 500000] }, { zoom: 8 });
    return;
  }

  if (!selectedLandeign) {
    return;
  } else if (selectedLandeign.features.length === 0) {
    view.animate({ center: [item.x, item.y] }, { zoom: 19 });
    return;
  }

  const landeignarnumer = selectedLandeign.features[0].properties.LANDEIGN_NR;
  setPath(landeignarnumer);
  const manySkikar = selectedLandeign.features.length > 1;
  view.animate(
    { center: [item.x, item.y] },
    {
      zoom:
        zoomLevelByArea(
          selectedLandeign.features[0].properties.LANDEIGN_MAELD_STAERD ?? 0
        ) - (manySkikar ? 1 : 0),
    }
  );

  const landeign = landeignWmsToUiLandeign(selectedLandeign);
  const umAfmorkun = landeignWmsToUmAfmorkun(selectedLandeign);
  const selectedLandeignarnumer = selectedLandeign.features[0].properties.LANDEIGN_NR;

  return {
    landeign,
    umAfmorkun,
    selectedLandeignarnumer,
  };
};
