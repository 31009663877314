import { SearchLiteItem } from '../types/apiTypes';

import { apiServiceUrl } from './urlUtils';

export async function searchLandeign(query: string) {
  const apiUrl = apiServiceUrl(`/api/landeign/stadfang/searchLight/${query}`);
  let item: Array<SearchLiteItem> | undefined;
  try {
    const res: Response = await fetch(apiUrl);
    item = await res.json();
  } catch (e) {
    console.warn('unable to search', apiUrl, e);
  }

  return item;
}
