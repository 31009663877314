import { Gjaldandi, SkikarWMS, StadfongWMS } from '../types/apiTypes';

import { apiServiceUrl } from './urlUtils';

export async function fetchStadfongByLandeignarnumer(landeignNr: number) {
  const apiUrl = apiServiceUrl(`api/landeign/${landeignNr}/connectedStadfong`);
  let stadfong: StadfongWMS | undefined;
  try {
    const res: Response = await fetch(apiUrl);
    if (!res.ok) {
      throw new Error('non-200 from API');
    }
    stadfong = await res.json();
  } catch (e) {
    console.warn('unable to fetchConnectedStadfong', apiUrl, e);
  }

  if (!stadfong) {
    return null;
  }

  return stadfong.features;
}

export async function fetchSkikarByLandeignarnumer(id: number) {
  const apiUrl = apiServiceUrl(`api/landeign/${id}/connectedSkikar`);
  let skikar: SkikarWMS | null | undefined;

  try {
    const res: Response = await fetch(apiUrl);
    if (!res.ok) {
      throw new Error('non-200 from API');
    }
    skikar = await res.json();
  } catch (e) {
    console.warn('unable to fetchConnectedSkikar', apiUrl, e);
    return undefined;
  }

  if (!skikar) {
    skikar = null;
  }

  return skikar;
}

export async function fetchConnectedStadfongByLandeignarnumer(id: number) {
  const apiUrl = apiServiceUrl(`api/landeign/${id}/connectedStadfong`);
  let stadfong: StadfongWMS | undefined;
  try {
    const res: Response = await fetch(apiUrl);
    if (!res.ok) {
      throw new Error('non-200 from API');
    }
    stadfong = await res.json();
  } catch (e) {
    console.warn('unable to fetchConnectedStadfong', apiUrl, e);
    return undefined;
  }

  if (!stadfong) {
    return [];
  }

  return stadfong;
}

export async function fetchGjaldandiByLandeignarnumer(id: number) {
  const apiUrl = apiServiceUrl(`api/landeign/${id}/gjaldendur`);
  let res: Response | undefined;
  let obj: Array<Gjaldandi> | undefined;
  try {
    res = await fetch(apiUrl);

    if (!res.ok) {
      throw new Error('non-200 from API');
    }
  } catch (e) {
    console.warn('unable to fetchGjaldandiByLandeignNr', apiUrl, e);
    return undefined;
  }

  try {
    obj = await res.json();
  } catch (e) {
    // No content response
    return [];
  }

  if (!obj) {
    obj = [];
  }

  return obj;
}
