import { Coordinate } from 'ol/coordinate';
import Feature, { FeatureLike } from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';

import { roundNumber } from '../../utils/roundNumbers';
import { SOURCE_SELECTED_LANDEIGN, SOURCE_SELECTED_STADFONG } from '../layers/layers';

import { handleMultiPolygon, SkikiProperties } from './ol-utils';
import { selectedStadfangStyle, skikarSelectedStyle, skikarStyle } from './styles';

export type DrawType = Point | LineString | Polygon;

export const geometryHelper = (feature: FeatureLike) => {
  const type = (feature.getGeometry() as DrawType).getType();

  return type;
};

export const isPoint = (feature: FeatureLike) => {
  return geometryHelper(feature) === 'Point';
};

export const isLineString = (feature: FeatureLike) => {
  return geometryHelper(feature) === 'LineString';
};

export const isPolygon = (feature: FeatureLike) => {
  return geometryHelper(feature) === 'Polygon';
};

export const getPointCoordinates = (feature?: FeatureLike) => {
  if (feature && isPoint(feature)) {
    const pointCoords = (feature.getGeometry() as Point).getCoordinates();
    return pointCoords;
  }
  return undefined;
};

export const getLineCoordinates = (feature?: FeatureLike) => {
  if (feature && isLineString(feature)) {
    const pointCoords = (feature.getGeometry() as LineString).getCoordinates();
    return pointCoords;
  }
  return undefined;
};

export const getPolygonCoordinates = (feature?: FeatureLike) => {
  if (feature && isPolygon(feature)) {
    const pointCoords = (feature.getGeometry() as Polygon).getCoordinates();
    return pointCoords;
  }
  return undefined;
};

export const addPolygonToLayer = (
  coordinates: Array<Array<Coordinate>>,
  properties?: SkikiProperties
) => {
  const coord = handleMultiPolygon(coordinates, properties);

  if (!coord) {
    return;
  }

  const polygon = new Polygon(coord);
  const feature = new Feature({
    name: 'Polygon',
    type: 'removable',
    geometry: polygon,
    style: skikarStyle,
  });

  properties && feature.setProperties(properties);
  SOURCE_SELECTED_LANDEIGN.addFeature(feature);

  if (properties?.selected) {
    feature.setStyle(skikarSelectedStyle);
  }
};

export const addPointToLayer = (coordinate: Coordinate) => {
  const point = new Point(coordinate);
  const feature = new Feature({
    name: 'Point',
    type: 'removable',
    geometry: point,
    style: selectedStadfangStyle,
    selectedStadfang: true,
  });

  SOURCE_SELECTED_STADFONG.addFeature(feature);
};

// Find if a click is close enough to coordinate to count as click on it
export const equalCoordinates = (a: Coordinate, b: Coordinate) => {
  const aNorm = a.map((c) => roundNumber(c));
  const bNorm = b.map((c) => roundNumber(c));
  return JSON.stringify(aNorm) === JSON.stringify(bNorm);
};
