/* eslint-disable complexity */
import { useContext } from 'react';
import classnames from 'classnames/bind';

import { UIContext } from '../../context/ui';
import { clearLandeign } from '../../open-layers/utils/ol-click-utils';
import { setPath } from '../../utils/setPath';
import Icon from '../icon/Icon';
import Zoom from '../zoom/Zoom';

import LandupplysingarEigendur from './LandupplysingarEigendur';
import LandupplysingarLandeign from './LandupplysingarLandeign';
import LandupplysingarSkikar from './LandupplysingarSkikar';
import LandupplysingarStadfong from './LandupplysingarStadfong';
import LandupplysingarUmAfmorkun from './LandupplysingarUmAfmorkun';

import s from './Landupplysingar.module.scss';

const c = classnames.bind(s);

type Props = {
  skikarLoading: boolean;
  skikarError: boolean;
  stadfongLoading: boolean;
  stadfongError: boolean;
  plainStadfong?: Array<string>;
  eigendurLoading: boolean;
  eigendurError: boolean;
};

export default function Landupplysingar({
  skikarLoading,
  skikarError,
  stadfongLoading,
  stadfongError,
  plainStadfong,
  eigendurLoading,
  eigendurError,
}: Props) {
  const { uiState, setUiState } = useContext(UIContext);
  const { landeign, skikar, eigendur, stadfong, umAfmorkun, selectedLandeignarnumer } =
    uiState;

  const onClose = () => {
    setUiState({
      landeign: undefined,
      skikar: undefined,
      eigendur: undefined,
      stadfong: undefined,
      umAfmorkun: undefined,
    });
    clearLandeign();
    setPath();
  };

  if (
    !selectedLandeignarnumer &&
    (landeign || skikar || eigendur || stadfong || umAfmorkun)
  ) {
    onClose();
  }

  return (
    <div
      className={c(s.landUpplysingar, {
        open: landeign || skikar || eigendur || stadfong || umAfmorkun,
      })}
    >
      {landeign || skikar || eigendur || stadfong || umAfmorkun ? (
        <>
          <div className={s.landUpplysingar__topbar}>
            <h1 className={s.landUpplysingar__heading}>
              <strong>Landeign</strong>
              {` L${selectedLandeignarnumer}`}
            </h1>
            <button className={s.landUpplysingar__close} onClick={onClose}>
              <Icon icon="x" />
            </button>
          </div>
          <div className={s.landUpplysingar__outer}>
            <LandupplysingarLandeign landeign={landeign} />
            <LandupplysingarSkikar
              skikar={skikar}
              skikarLoading={skikarLoading}
              skikarError={skikarError}
            />
            <LandupplysingarUmAfmorkun umAfmorkun={umAfmorkun} />
            <LandupplysingarStadfong
              stadfong={stadfong}
              stadfongLoading={stadfongLoading}
              stadfongError={stadfongError}
              plainStadfong={plainStadfong}
            />
            <LandupplysingarEigendur
              eigendur={eigendur}
              eigendurLoading={eigendurLoading}
              eigendurError={eigendurError}
            />
          </div>
        </>
      ) : (
        <div className={s.landUpplysingar__fillHeight} />
      )}
      <div className={s.landUpplysingar__zoomWrapper}>
        <Zoom />
      </div>
    </div>
  );
}
