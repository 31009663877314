import React, { useContext, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import classnames from 'classnames/bind';
import { getCenter } from 'ol/extent';

import { UIContext, UiSkikar } from '../../context/ui';
import { SOURCE_SELECTED_LANDEIGN } from '../../open-layers/layers/layers';
import { colorSecondary } from '../../open-layers/utils/colors';
import { specialLandeignarnumer } from '../../open-layers/utils/constants';
import {
  skikarHoverAndSelectedStyle,
  skikarHoverStyle,
  skikarSelectedStyle,
  skikarStyle,
} from '../../open-layers/utils/styles';
import Area from '../area/Area';
import AttentionBox from '../atention-box/AttentionBox';
import CountBubble from '../count-bubble/CountBubble';
import InfoHeading from '../info-heading/InfoHeading';

import s from './Landupplysingar.module.scss';

const c = classnames.bind(s);

type Props = {
  skikar?: UiSkikar | undefined;
  skikarLoading: boolean;
  skikarError?: boolean;
};

export default function LandupplysingarSkikar({ skikar, skikarLoading }: Props) {
  const [hoverSkiki, setHoverSkiki] = useState<string | undefined>();
  const [selectedSkiki, setSelectedSkiki] = useState<string | undefined>();
  const { uiState, setUiState } = useContext(UIContext);
  const { selectedLandeignarnumer, theMap } = uiState;
  const features = SOURCE_SELECTED_LANDEIGN.getFeatures();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!features) {
      return;
    }

    let anyHover: boolean | undefined;
    let anySelected: boolean | undefined;
    features.forEach((feature) => {
      const hover = feature.get('hover');
      const selected = feature.get('selected');
      const skiki = feature.get('skiki');
      if (hover) {
        anyHover = true;
        setHoverSkiki(skiki);
      }

      if (selected) {
        anySelected = true;
        setSelectedSkiki(skiki);
      }
    });

    if (!anyHover) {
      setHoverSkiki(undefined);
    }

    if (!anySelected) {
      // Should not happen...
      setSelectedSkiki(undefined);
    }
  }, [features]);

  const onMouseEnter = (s: string) => {
    const features = SOURCE_SELECTED_LANDEIGN.getFeatures();
    features.forEach((feature) => {
      const skiki = feature.get('skiki');
      const hover = feature.get('hover');
      const selected = feature.get('selected');
      if (skiki === s && !hover) {
        feature.setProperties({ hover: true });
        feature.setStyle(selected ? skikarHoverAndSelectedStyle : skikarHoverStyle);

        setHoverSkiki(s);
      }
    });
  };

  const onMouseLeave = (s: string) => {
    const features = SOURCE_SELECTED_LANDEIGN.getFeatures();
    features.forEach((feature) => {
      const skiki = feature.get('skiki');
      const selected = feature.get('selected');
      if (skiki === s) {
        feature.setProperties({ hover: false });
        feature.setStyle(selected ? skikarSelectedStyle : skikarStyle);
      }
    });
  };

  const onClick = (
    e: React.MouseEvent<HTMLLIElement>,
    clickedSkiki: string | undefined,
    selected: boolean
  ) => {
    if (selected || !clickedSkiki || !theMap) {
      return;
    }

    features.forEach((feature) => {
      const skiki = feature.get('skiki');
      feature.setProperties({ selected: clickedSkiki === skiki ? true : false });
      if (clickedSkiki === skiki) {
        feature.setStyle(skikarHoverAndSelectedStyle);
        // Move view to the skiki selected in the list
        const view = theMap.getView();
        const extent = feature.getGeometry()?.getExtent();
        const center = extent && getCenter(extent);
        center && view.animate({ center });
      } else {
        feature.setStyle(skikarStyle);
      }
    });

    skikar?.skikar?.forEach((skiki) => {
      if (skiki.skiki === clickedSkiki) {
        setUiState({
          umAfmorkun: {
            adferd: skiki.adferd,
            nakvaemni: `${skiki.nakvaemni ?? 'Ekki vitað'}`,
            heimild: skiki.heimild,
            athugasemd: skiki.athugasemd ?? undefined,
          },
        });
      }
    });

    setSelectedSkiki(clickedSkiki);
  };

  return (
    <div className={s.landUpplysingar__inner}>
      <InfoHeading
        id="afmorkun"
        heading="Afmörkun"
        info={false}
        countBubble={
          skikar && skikar.count && skikar.count > 0 ? (
            <CountBubble
              count={
                selectedLandeignarnumer === specialLandeignarnumer ? 1 : skikar.count
              }
            />
          ) : (
            ''
          )
        }
      />
      <ul className={s.landUpplysingar__listSkikar}>
        {skikarLoading ? (
          <li className={s.landUpplysingar__listItem}>
            <ClipLoader size={'20px'} color={colorSecondary} css="align-self: center" />
          </li>
        ) : skikar && skikar.count && skikar.count > 0 ? (
          skikar.skikar?.map((skiki, i) => {
            return (
              <React.Fragment key={i}>
                {i === 0 && (
                  <li className={s.landUpplysingar__listItem}>
                    <p className={s.landUpplysingar__listTitle}>Skiki</p>
                    <p className={s.landUpplysingar__listTitle}>Mæld stærð</p>
                  </li>
                )}
                {(selectedLandeignarnumer === specialLandeignarnumer &&
                  skiki.skiki === selectedSkiki) ||
                selectedLandeignarnumer !== specialLandeignarnumer ? (
                  <li
                    className={c(s.landUpplysingar__listItem, {
                      hover: skiki.skiki === hoverSkiki,
                      selected: skiki.skiki === selectedSkiki,
                      clickable: true,
                    })}
                    onMouseEnter={() => {
                      onMouseEnter(skiki.skiki ?? '');
                    }}
                    onMouseLeave={() => {
                      onMouseLeave(skiki.skiki ?? '');
                    }}
                    onClick={(e) => {
                      onClick(e, skiki.skiki, skiki.skiki === selectedSkiki);
                    }}
                  >
                    <p className={s.landUpplysingar__listContentShort}>{skiki.skiki}</p>
                    <p className={s.landUpplysingar__listContent}>
                      {skiki.maeldStaerd ? (
                        <Area size={skiki.maeldStaerd} />
                      ) : (
                        'Ekki mælt'
                      )}
                    </p>
                  </li>
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          <li className={s.landUpplysingar__attentionBox}>
            <AttentionBox richText={<p>Engar afmarkanir fundust.</p>} />
          </li>
        )}
      </ul>
    </div>
  );
}
