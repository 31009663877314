import { createContext, useCallback, useState } from 'react';
import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { EventsKey } from 'ol/events';

import { StadfangFeatureProperties } from '../types/apiTypes';
import { BaseMap, LayerToggle } from '../types/data';

type LandeignPopOver = {
  title?: string;
  landeignarnumer?: number;
  n?: number;
  w?: number;
};

export type UiLandeign = {
  audkenni: string;
  sveitarfelag: string;
  gerd?: string;
  skradStaerd?: number;
  maeldStaerd?: number;
};

export type UiAfmorkun = {
  skiki?: string;
  maeldStaerd?: number;
};

export type UiSkiki = {
  skiki?: string;
  maeldStaerd?: number;
  coordinates?: Array<Array<Coordinate>>;
  adferd?: string;
  nakvaemni?: number;
  heimild?: string;
  athugasemd?: string | null;
};

export type UiSkikar = {
  count?: number;
  skikar?: Array<UiSkiki>;
};

export type UiEigendur = {
  name?: string;
  ratioOwned?: number;
};

export type UiStadfong = {
  count: number;
  stadfong: Array<StadfangFeatureProperties>;
};

export type UmAfmorkun = {
  adferd?: string;
  nakvaemni: string;
  heimild?: string;
  athugasemd?: string;
  audkenni?: string;
};

export type UIState = {
  isLoading?: boolean;
  isNavOpen?: boolean;
  toolsOpen?: 'search' | 'info' | 'changeMaps' | 'measure' | 'layers' | boolean;
  mLength?: boolean;
  mArea?: boolean;
  mAngle?: boolean;
  // For info window
  landeign?: UiLandeign;
  skikar?: UiSkikar;
  eigendur?: Array<UiEigendur>;
  stadfong?: UiStadfong;
  umAfmorkun?: UmAfmorkun;
  //Other
  selectedLandeignarnumer?: number;
  activeMap?: BaseMap;
  externalLayer?: LayerToggle;
  clickKey?: EventsKey;
  landeignPopover?: LandeignPopOver;
  theMap?: Map;
  mapClickOn?: boolean;
};

type UIContext = {
  uiState: UIState;
  setUiState: (args: UIState) => void;
};

export const uiContextDefaults = {
  // Tools
  isLoading: false,
  isNavOpen: false,
  toolsOpen: false,
  mLength: false,
  mArea: false,
  mAngle: false,
  // For info window
  landeign: undefined,
  eigendur: undefined,
  stadfong: undefined,
  umAfmorkun: undefined,
  // Map state
  selectedLandeignarnumer: undefined,
  activeMap: {
    bingMaps: false,
    kortamynd: true,
    osm: false,
  },
  externalLayer: {
    landeignir: true,
    stadfong: true,
    postnumer: false,
    sveitarfelog: false,
    nytjaland2006: false,
    vegir: false,
  },
  clickKey: undefined,
  landeignPopOver: {
    title: undefined,
    landeignarnumer: undefined,
    n: undefined,
    w: undefined,
  },
  theMap: undefined,
  mapClickOn: true,
};

// export UIContext. usage: React.useContext(UIContext);
export const UIContext = createContext<UIContext>({
  uiState: uiContextDefaults,

  setUiState: () => null,
});

// exported UIProvider Component
// wraps _app for children to optionally consume with useContext() hook
export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [uiState, updateUiState] = useState<UIState>(uiContextDefaults);

  const setUiState = useCallback((state: UIState) => {
    updateUiState((prevState) => ({
      ...prevState,
      ...state,
    }));
  }, []);

  return (
    <UIContext.Provider
      value={{
        uiState,
        setUiState,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
