import { sveitarfelog } from '../open-layers/utils/constants';

export const getSveitarfelag = (svNr?: string) => {
  if (svNr && svNr === '0000') {
    return 'Reykjavíkurborg (0000)';
  }
  if (svNr && sveitarfelog[Number(svNr) as keyof typeof sveitarfelog]) {
    return `${sveitarfelog[Number(svNr) as keyof typeof sveitarfelog]} (${svNr})`;
  }

  return 'Ekki vitað';
};

/*
export const getSveitarfelagOnly = (svNr?: string) => {
  if (svNr && (svNr === '0000' || svNr === '0')) {
    return 'Reykjavíkurborg';
  }
  if (svNr && sveitarfelogShort[Number(svNr) as keyof typeof sveitarfelog]) {
    return `${sveitarfelogShort[Number(svNr) as keyof typeof sveitarfelog]}`;
  }

  return false;
};
*/
