import React, { useContext, useEffect } from 'react';

import { UIContext } from '../../context/ui';
import { changeBaseMap } from '../../open-layers/utils/ol-map-utils';
import Radio from '../form-components/Radio';

import s from './ChangeMaps.module.scss';

export default function ChangeMaps() {
  const { uiState, setUiState } = useContext(UIContext);
  const { activeMap } = uiState;

  // Handle user changes to which base map is in use
  useEffect(() => {
    if (activeMap) {
      changeBaseMap(activeMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMap]);

  const onChange = (e: React.FormEvent<HTMLFormElement | HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setUiState({
      activeMap: {
        kortamynd: value === 'kortamynd',
        osm: value === 'osm',
        bingMaps: value === 'bingMaps',
      },
    });
  };

  return (
    <div className={s.changeMaps}>
      <h2 className={s.changeMaps__title}>Skipta um grunnkort</h2>
      <div>
        <form action="post">
          <div className={s.changeMaps__radioWrapper}>
            <Radio
              label="Kortamynd"
              name="baseMap"
              value="kortamynd"
              id="kortamynd"
              checked={activeMap?.kortamynd ?? false}
              onChange={onChange}
            />
          </div>
          <div className={s.changeMaps__radioWrapper}>
            <Radio
              label="Open Street Map"
              name="baseMap"
              value="osm"
              id="osm"
              checked={activeMap?.osm ?? false}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className={s.changeMaps__radioWrapper}>
            <Radio
              label="Bing Maps"
              name="baseMap"
              value="bingMaps"
              id="bingMaps"
              checked={activeMap?.bingMaps ?? false}
              onChange={(e) => onChange(e)}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
