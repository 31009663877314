// Array of resolutions that tells the map how far each zoom level should go
export const resolutions = [
  2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625,
];

// Bounding box for the INS93 coordinate system
export const bbox = [43000, 255000, 966000, 735000];

// The junkyard ID, has a lot of plots that needs to be sorted out further
export const specialLandeignarnumer = 999999;

// All munucipalities in Iceland, Reykjavík is 0000 and is handled separately
export const sveitarfelog = {
  1000: 'Kópavogsbær',
  1100: 'Seltjarnarnesbær',
  1300: 'Garðabær',
  1400: 'Hafnarfjarðarkaupstaður',
  1603: 'Sveitarfélagið Álftanes',
  1604: 'Mosfellsbær',
  1606: 'Kjósarhreppur',
  2000: 'Reykjanesbær',
  2300: 'Grindavíkurbær',
  2503: 'Sandgerðisbær',
  2504: 'Sveitarfélagið Garður',
  2506: 'Sveitarfélagið Vogar',
  3000: 'Akraneskaupstaður',
  3506: 'Skorradalshreppur',
  3511: 'Hvalfjarðarsveit',
  3609: 'Borgarbyggð',
  3709: 'Grundarfjarðarbær',
  3710: 'Helgafellssveit',
  3711: 'Stykkishólmsbær',
  3713: 'Eyja- og Miklaholtshreppur',
  3714: 'Snæfellsbær',
  3811: 'Dalabyggð',
  4100: 'Bolungarvíkurkaupstaður',
  4200: 'Ísafjarðarbær',
  4502: 'Reykhólahreppur',
  4604: 'Tálknafjarðarhreppur',
  4607: 'Vesturbyggð',
  4803: 'Súðavíkurhreppur',
  4901: 'Árneshreppur',
  4902: 'Kaldraneshreppur',
  4908: 'Bæjarhreppur',
  4911: 'Strandabyggð',
  5200: 'Sveitarfélagið Skagafjörður',
  5508: 'Húnaþing vestra',
  5604: 'Blönduósbær',
  5609: 'Sveitarfélagið Skagaströnd',
  5611: 'Skagabyggð',
  5612: 'Húnavatnshreppur',
  5613: 'Húnabyggð',
  5706: 'Akrahreppur',
  5716: 'Skagafjörður',
  6000: 'Akureyrarkaupstaður',
  6100: 'Norðurþing',
  6250: 'Fjallabyggð',
  6400: 'Dalvíkurbyggð',
  6513: 'Eyjafjarðarsveit',
  6515: 'Hörgársveit',
  6601: 'Svalbarðsstrandarhreppur',
  6602: 'Grýtubakkahreppur',
  6607: 'Skútustaðahreppur',
  6611: 'Tjörneshreppur',
  6612: 'Þingeyjarsveit',
  6613: 'Þingeyjarsveit',
  6706: 'Svalbarðshreppur',
  6709: 'Langanesbyggð',
  6710: 'Langanesbyggð',
  7000: 'Seyðisfjarðarkaupstaður',
  7300: 'Fjarðabyggð',
  7400: 'Múlaþing',
  7502: 'Vopnafjarðarhreppur',
  7505: 'Fljótsdalshreppur',
  7509: 'Borgarfjarðarhreppur',
  7613: 'Breiðdalshreppur',
  7617: 'Djúpavogshreppur',
  7620: 'Fljótsdalshérað',
  7708: 'Sveitarfélagið Hornafjörður',
  8000: 'Vestmannaeyjabær',
  8200: 'Sveitarfélagið Árborg',
  8401: 'Sveitarfélagið Hornafjörður',
  8508: 'Mýrdalshreppur',
  8509: 'Skaftárhreppur',
  8610: 'Ásahreppur',
  8613: 'Rangárþing eystra',
  8614: 'Rangárþing ytra',
  8710: 'Hrunamannahreppur',
  8716: 'Hveragerðisbær',
  8717: 'Sveitarfélagið Ölfus',
  8719: 'Grímsnes- og Grafningshreppur',
  8720: 'Skeiða- og Gnúpverjahreppur',
  8721: 'Bláskógabyggð',
  8722: 'Flóahreppur',
};
