import classnames from 'classnames/bind';

import { ReactComponent as Hms } from '../../assets/svg/hms-logo.svg';
import { ReactComponent as InfoCircleAlt } from '../../assets/svg/info-circle-alt.svg';
import { ReactComponent as Layers } from '../../assets/svg/layers2.svg';
import { ReactComponent as Magnify } from '../../assets/svg/magnify.svg';
import { ReactComponent as MapIcon } from '../../assets/svg/map.svg';
import { ReactComponent as Measure } from '../../assets/svg/measure.svg';
import { ReactComponent as MeasureAngle } from '../../assets/svg/measure-angle.svg';
import { ReactComponent as Area } from '../../assets/svg/measure-area.svg';
import { ReactComponent as Line } from '../../assets/svg/measure-length.svg';
import { ReactComponent as Trash } from '../../assets/svg/trash.svg';
import { ReactComponent as Triangle } from '../../assets/svg/triangle.svg';
import { ReactComponent as X } from '../../assets/svg/x.svg';
import { ReactComponent as ZoomIn } from '../../assets/svg/zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../assets/svg/zoom-out.svg';

import s from './Icon.module.scss';

const c = classnames.bind(s);

type Props = {
  icon:
    | 'search'
    | 'info'
    | 'iceland'
    | 'measure'
    | 'layers'
    | 'line'
    | 'area'
    | 'angle'
    | 'trash'
    | 'x'
    | 'hms'
    | 'triangle'
    | 'annad'
    | 'zoomin'
    | 'zoomout';
  onClick?: () => void;
  wide?: boolean;
};

export default function Icon({ icon, onClick, wide = false }: Props) {
  if (icon === 'search') {
    return <Magnify className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'info') {
    // return <InfoCircle className={s.icon} onClick={onClick} />;
    return <InfoCircleAlt className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'iceland') {
    return <MapIcon className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'measure') {
    return <Measure className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'layers') {
    return <Layers className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'line') {
    return <Line className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'area') {
    return <Area className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'angle') {
    return <MeasureAngle className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'trash') {
    return <Trash className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'x') {
    return <X className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'hms') {
    return <Hms className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'triangle') {
    return <Triangle className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'zoomin') {
    return <ZoomIn className={c(s.icon, { wide })} onClick={onClick} />;
  } else if (icon === 'zoomout') {
    return <ZoomOut className={c(s.icon, { wide })} onClick={onClick} />;
  } else {
    return null;
  }
}
