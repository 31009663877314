// Functions that the measurment tools need
import { Map } from 'ol';

import { LAYER_SELECTED_LANDEIGN, SOURCE_DRAW } from '../layers/layers';
import { measureArea, stopMeasuringArea } from '../tools/measure-area';
import { measureLength, stopMeasuringLength } from '../tools/measure-length';
import { measureAngle, stopMeasuringAngle } from '../tools/meaure-angle';

export const toggleDrawInteraction = (
  on: boolean,
  map: Map,
  type: 'LineString' | 'Polygon' | 'Angle' | 'Off'
) => {
  if (type === 'LineString' && on) {
    measureLength(map);
    stopMeasuringArea(map);
    stopMeasuringAngle(map);
  } else if (type === 'Polygon' && on) {
    measureArea(map);
    stopMeasuringLength(map);
    stopMeasuringAngle(map);
  } else if (type === 'Angle' && on) {
    measureAngle(map);
    stopMeasuringArea(map);
    stopMeasuringLength(map);
  } else {
    stopMeasuringArea(map);
    stopMeasuringLength(map);
    stopMeasuringAngle(map);
  }

  LAYER_SELECTED_LANDEIGN.setVisible(on);
};

export const clearDrawings = (map: Map) => {
  SOURCE_DRAW.clear();
  map.getOverlays().clear();
};
